import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { showFixedLabel } from '../../../actions/fixedLabel';
import { ROUTE } from '../../../common/constants/routes';
import { startRequest } from '@backoffice/actions/apiCall';
import { closeModal, openModal } from '../../../actions/popup';
import {
    getRequestDetailByID,
    getUserLoggedParams,
    getAllAuditorCompaniesParams,
    postBudgetMediaParams,
    postContractsMediaParams,
    postEvaluationMediaParams,
    postSealMediaParams,
    postSaveDetail,
    getAuditorUsersByAuditorCompanyIdParams,
    postReactivateCompany,
    postEvaluationPuntuationsMediaParams
} from './RequestDetailCalls/RequestDetailCalls'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import Input from '../../../components/FormComponents/Input';
import Button from '../../../components/FormComponents/Button';
import Checkbox from '../../../components/FormComponents/Checkbox';
import Logo from '../../../assets/logos/me_azul2.png'
import './RequestDetail.scss';
import Combo from '../../../components/FormComponents/Combo';
import FixedLabel from '../../../shared/FixedLabel/FixedLabel';
import RequestDetailModal from './RequestDetailModal/RequestDetailModal';
import CompaniesAdditionalData from '../Companies/CompaniesAdditionalData';
import RequestApplication from './RequestApplication';
import RequestBudget from './RequestBudget';
import RequestQuestionnaire from './RequestQuestionnaire';
import RequestContracts from './RequestContracts';
import RequestSeal from './RequestSeal';
import RequestVisitEvaluator from './RequestVisitEvaluator';
import RequestDataEvaluator from './RequestDataEvaluator';
import RequestInformEvaluatorForm from './RequestInformEvaluatorForm';
import RequestInformEvaluator from './RequestInformEvaluator';
import RequestSelectEvaluator from './RequestSelectEvaluator';
import RequestImprovementPlans from './RequestImprovementPlans';
import { checkIsPrincipalOneCompany } from '../../../common/utils/checkIsPrincipal';
import Radio from '../../../components/FormComponents/RadioButton/Radio/Radio';
import { getExcelAutoEvaluationDataBase64Params, getNotCombinedClientCompanies } from '../../Pages/Request/RequestCalls/RequestCalls';
import { orderComboByText } from '../../../common/utils/parseUtils';

const RequestDetail = (props) => {
    const history = useHistory()
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const [requestId, setRequestId] = useState()
    const [addSecondVisitDate, setAddSecondVisitDate] = useState(false)
    const [userLogged, setUserLogged] = useState(null)
    const [userRol, setUserRol] = useState('')
    const [disableDataBlocks, setDisableDataBlocks] = useState(false)
    const [showAdditionalData, setShowAdditionalData] = useState(false)
    const [companyId, setCompanyId] = useState(null);
    const [firstLoad, setFirstLoad] = useState(false);
    const [showQuestionnarie, setShowQuestionnarie] = useState(false);
    const [showEvaluatorForm, setShowEvaluatorForm] = useState(false);
    const [isPrincipalCompany, setIsPrincipalCompany] = useState(false);
    const [parentCompanyName, setParentCompanyName] = useState(null);
    const [jointEvaluation, setJointEvaluation] = useState(null)
    const [companiesJointEvaluation, setCompaniesJointEvaluation] = useState(null)
    const [companyJointEvaluationSelected, setCompanyJointEvaluationSelected] = useState(null)
    const [isPublicCompany, setIsPublicCompany] = useState(null)

    //Combo Auditors
    const [auditorComboOptions, setAuditorComboOptions] = useState([])
    const [auditorComboSelected, setAuditorComboSelected] = useState([])

    const [auditorsByCompanyComboOptions, setAuditorsByCompanyComboOptions] = useState([])
    const [auditorsByCompanyComboSelected, setAuditorsByCompanyComboSelected] = useState([])

    const [evaluatorDataComboSelected, setEvaluatorDataComboSelected] = useState([]);

    //let ME choose Auditor Company
    const [letAdminChooseAuditorCompany, setletAdminChooseAuditorCompany] = useState(false)

    //Renewal autorization for Company
    const [numberEmployees, setNumberEmployees] = useState("");
    const [revenueVolumen, setRevenueVolumen] = useState("");    
    const [isEvaluationPublic, setIsEvaluationPublic] = useState(null);
    const [formValues, setFormValues] = useState({
        id: requestId,
        RequestDate: null,
        FileNumber: 0,
        SocialName: "",
        ComercialName: "",
        ContactName: "",
        ContactSurnames: "",
        ContactEmail: "",
        ContactPhone: "",
        RequestState: "",
        //RequestLink: "",
        BudgetState: '',
        BudgetDate: null,
        BudgetFileName: '',
        BudgetFileLink: '',
        QuestionnaireState: '',
        QuestionnaireCompanyTotalPoints: 0,
        QuestionnaireEvaluatorTotalPoints: 0,
        QuestionnaireCompletePercent: 0,
        IsAuditorComplete: false,
        //QuestionnaireIsAuthorizated: false,
        ContractsAndAnnexesDate: null,
        ContractsAndAnnexesFileName: '',
        ContractsAndAnnexesFileLink: '',
        SealState: '',
        SealDate: null,
        SealConcessionDate: null,
        SealConcessionRenewal: null,
        SealFileName: '',
        SealFileLink: '',
        FirstVisitingDate: null,
        SecondVisitingDate: null,
        EvaluatorsNumber: 0,
        EvaluatorName: '',
        EvaluatorSurnames: '',
        EvaluatorPhone: '',
        EvaluatorEmail: '',
        EvaluatorEntity: '',
        EvaluationState: '',
        EvaluationDate:  null,
        EvaluationFileName: '',
        EvaluationFileLink: '',
        EvaluationDate2: null,
        EvaluationFileName2: '',
        EvaluationFileLink2: '',
        EvaluationIsAuthorized: false,
        EvaluatorCompanyId: '',
        AllowAdminSelection: false,
        EvaluatorsListData: [],
        EvaluatorsList: [],
        requestType: 0,
        requestTypeValue: '',
        DeregisterCompanyStatusId: '',
        DeregisterCompanyStatusName: '',
        CompanyParentId: '',
        IsCombined: false
    })

    const [filesData, setFilesData] = useState({
        budgetMedia: {
            id: "",
            name: "",
            url: ""
        },
        contractsMedia: {
            id: "",
            name: "",
            url: ""
        },
        sealMedia: {
            id: "",
            name: "",
            url: ""
        },
        evaluationMedia: {
            id: "",
            name: "",
            url: ""
        }
    })

    useEffect(() => {
        getUserLoged();
        if(history.location.state){
            setRequestId(history.location.state.requestId)
        }else{
            //get id from params
            const params = window.location.pathname
            setRequestId(params.split('/')[2])
        }

    }, [])

    const getUserLoged = () =>{
        dispatch(startRequest(getUserLoggedParams(getUserLoggedParamsSuccess)))
	}

    const getUserLoggedParamsSuccess = (data) => {
        setUserLogged(data)
        setUserRol(data.userRoles[0].name)
    }

    useEffect(() => {
        if(requestId) getRequestDetail()
    }, [userRol])

    const getRequestDetail = () => {
        dispatch(startRequest(getRequestDetailByID(requestId, getRequestDetailByIDSuccess)))
    }
    
    const getRequestDetailByIDSuccess = (data) =>{
        if(data) {
            const firstVisitDate = data.evaluationData.firstVisitingDate;
            const secondVisitDate = data.evaluationData.secondVisitingDate;

            let firstVisitDateToBack;
            let secondVisitDateToBack;
            if(firstVisitDate){
                const firstVisitDateUtc = new Date(firstVisitDate);
                firstVisitDateUtc.setTime(firstVisitDateUtc.getTime() - firstVisitDateUtc.getTimezoneOffset()*60*1000);
                firstVisitDateToBack = firstVisitDateUtc.setHours(firstVisitDateUtc.getHours());
            }
            if(secondVisitDate) {
                const secondVisitDateUtc = new Date(secondVisitDate);
                secondVisitDateUtc.setTime(secondVisitDateUtc.getTime() - secondVisitDateUtc.getTimezoneOffset()*60*1000);
                secondVisitDateToBack = secondVisitDateUtc.setHours(secondVisitDateUtc.getHours())
            }
            setIsEvaluationPublic(data.isEvaluationPublic);
            setFormValues({
                ...formValues,
                RequestDate: data.dateRequest ? new Date(data.dateRequest) : null,
                FileNumber: data.fileNumber,
                SocialName: data.socialName,
                ComercialName: data.commercialName,
                ContactName: data.contactName ? data.contactName : '',
                ContactSurnames: data.contactSurnames ? data.contactSurnames : '',
                ContactEmail: data.contactEmail ? data.contactEmail : '',
                ContactPhone: data.contactPhoneNumber ? data.contactPhoneNumber : '',
                RequestState: data.requestStatusName,
                BudgetState: data.budgetStatusData.budgetStatusName,
                BudgetDate: data.budgetStatusData.date ? new Date(data.budgetStatusData.date) : null,
                BudgetFileName: data.budgetStatusData.fileName ? data.budgetStatusData.fileName : '',
                BudgetFileLink: data.budgetStatusData.fileUrl ? data.budgetStatusData.fileUrl : '',
                AutoevaluationId: data.questionnaireStatusData.autoevaluationId,
                QuestionnaireState: data.questionnaireStatusData.questionnaireStatusName,
                QuestionnaireCompanyTotalPoints: data.questionnaireStatusData.totalScoreCompany,
                QuestionnaireEvaluatorTotalPoints: data.questionnaireStatusData.totalScoreAuditor,
                QuestionnaireCompletePercent: data.questionnaireStatusData.percentageOfProgress,
                QuestionnaireVersion: data.questionnaireStatusData.questionnaireVersion,
                IsAuditorComplete: data.questionnaireStatusData.isAuditorCompleted,
                ContractsAndAnnexesDate: data.contractsData.date ? new Date(data.contractsData.date) : null,
                ContractsAndAnnexesFileName: data.contractsData.fileName ? data.contractsData.fileName : '',
                ContractsAndAnnexesFileLink: data.contractsData.fileLink ? data.contractsData.fileLink : '',
                ContractsStatusId: data.contractsData.contractsStatusId ? data.contractsData.contractsStatusId : '',
                ContractsStatusName: data.contractsData.contractsStatusName ? data.contractsData.contractsStatusName : '',
                SealState: data.sealStatusDetail.sealStatusName,
                SealDate: data.sealStatusDetail.dateConcession ? new Date(data.sealStatusDetail.dateConcession) : null,
                SealConcessionDate: data.sealStatusDetail.dateConcession ? new Date(data.sealStatusDetail.dateConcession) : null,
                SealConcessionRenewal: data.sealStatusDetail.dateRenewal ? new Date(data.sealStatusDetail.dateRenewal) : null,
                SealFileName: data.sealStatusDetail.fileName ? data.sealStatusDetail.fileName : '',
                SealFileLink: data.sealStatusDetail.fileLink ? data.sealStatusDetail.fileLink : '',
                EvaluatorsNumber: data.evaluationData.auditorsIdsList ? data.evaluationData.auditorsIdsList.length : 0,
                FirstVisitingDate: data.evaluationData.firstVisitingDate ? new Date(firstVisitDateToBack) : null,
                SecondVisitingDate: data.evaluationData.secondVisitingDate ? new Date(secondVisitDateToBack) : null,
                EvaluatorName: data.evaluationData.userAuditor ? data.evaluationData.userAuditor.name : '',
                EvaluatorSurnames: data.evaluationData.userAuditor ? data.evaluationData.userAuditor.surnames : '',
                EvaluatorPhone: data.evaluationData.userAuditor ? data.evaluationData.userAuditor.phoneNumber : '',
                EvaluatorEmail: data.evaluationData.userAuditor ? data.evaluationData.userAuditor.email : '',
                EvaluatorEntity: data.evaluationData.userAuditor ? data.evaluationData.userAuditor.companyName : '',
                EvaluationState: data.evaluationStatusData.evaluationStatusName,
                EvaluationDate: data.evaluationStatusData.date ? data.evaluationStatusData.date : null,
                EvaluationFileName: data.evaluationStatusData.fileName ? data.evaluationStatusData.fileName : '',
                EvaluationFileLink: data.evaluationStatusData.fileUrl ? data.evaluationStatusData.fileUrl : '',
                EvaluationDate2: data.evaluationStatusData.media2Date ? data.evaluationStatusData.media2Date : null,
                EvaluationFileName2: data.evaluationStatusData.media2FileName ? data.evaluationStatusData.media2FileName : '',
                EvaluationFileLink2: data.evaluationStatusData.media2FileUrl ? data.evaluationStatusData.media2FileUrl : '',
                EvaluationIsAuthorized: false,
                EvaluatorCompanyId: data.evaluationData.companyAuditorId ? data.evaluationData.companyAuditorId : '',
                AllowAdminSelection: data.evaluationData.allowAdminSelection,
                EvaluatorsListData: data.evaluationData.auditorsIdsList ? data.evaluationData.auditorsIdsList : [],
                requestType: data.requestType,
                requestTypeValue: data.requestTypeValue,
                ImprovementPlanStatusId: data.improvementPlanStatusData.improvementPlanStatusId,
                ImprovementPlanStatusName: data.improvementPlanStatusData.improvementPlanStatusName,
                ImprovementPlans: data.improvementPlanStatusData.improvementPlans.length > 0 ? data.improvementPlanStatusData.improvementPlans : [],
                IndicatedImprovementPlans: data.improvementPlanStatusData.indicatedImprovementPlans ? data.improvementPlanStatusData.indicatedImprovementPlans : 0,
                DeregisterCompanyStatusName: data.deregisterCompanyStatusData.deregisterCompanyStatusName,
                DeregisterCompanyStatusId: data.deregisterCompanyStatusData.deregisterCompanyStatusId,
                CompanyParentId: data.companyParentId ? data.companyParentId : '',
                IsCombined: data.isCombined
            })

            if(data.evaluationData.secondVisitingDate) setAddSecondVisitDate(false)
            else setAddSecondVisitDate(false)

            setShowQuestionnarie(getIsPrincipalCompany(data));
            setShowEvaluatorForm(getShowEvaluatorForm(data, data.companyId));
            setIsPrincipalCompany(getIsPrincipalCompany(data));

            // Radio for joint evalutaion
            setJointEvaluation(data.isCombined)

            setNumberEmployees(data.renewalData.numEmployees ? data.renewalData.numEmployees : "")
            setRevenueVolumen(data.renewalData.revenueVolume ? data.renewalData.revenueVolume : "")

            //Set Check Evaluation Company
            setletAdminChooseAuditorCompany(data.evaluationData.allowAdminSelection)

            //Set Auditor company Combo
            if(data.evaluationData.companyAuditorId){
                const companyId = data.evaluationData.companyAuditorId;
                const auditorCompanyById = auditorComboOptions.filter((x) => x.id === companyId)
                setAuditorComboSelected(auditorCompanyById)
            }

            //set files
            setFilesData({
                budgetMedia: {
                    id: "",
                    name: data.budgetStatusData.fileName ? data.budgetStatusData.fileName : '',
                    url: data.budgetStatusData.fileUrl ? data.budgetStatusData.fileUrl : ''
                },
                contractsMedia: {
                    id: "",
                    name: data.contractsData.fileName ? data.contractsData.fileName : '',
                    url: data.contractsData.fileUrl ? data.contractsData.fileUrl : ''
                },
                sealMedia: {
                    id: "",
                    name: data.sealStatusDetail.fileName ? data.sealStatusDetail.fileName : '',
                    url: data.sealStatusDetail.fileUrl ? data.sealStatusDetail.fileUrl : ''
                },
                evaluationMedia: {
                    id: "",
                    name: data.evaluationStatusData.fileName ? data.evaluationStatusData.fileName : '',
                    url: data.evaluationStatusData.fileUrl ? data.evaluationStatusData.fileUrl : ''
                },
                evaluationMedia2: {
                    id: "",
                    name: data.evaluationStatusData.media2FileName ? data.evaluationStatusData.media2FileName : '',
                    url: data.evaluationStatusData.media2FileUrl ? data.evaluationStatusData.media2FileUrl : ''
                }
            })

            setCompanyId(data.companyId)   
            setIsPublicCompany(data.isPublicCompany)
            setParentCompanyName(data.companyParentName)      
            setFirstLoad(true)
        }
    }

    useEffect(() => {
        firstLoad && getAllAuditorCompanies()
    }, [firstLoad])

    const getAllAuditorCompanies = () => {
        dispatch(startRequest(getAllAuditorCompaniesParams(getAllAuditorCompaniesParamsSuccess)))
    }

    const getAllAuditorCompaniesParamsSuccess = (data) => {
        let options = [];
        data.forEach((el) => {
            if(!el.isDeleted || formValues.EvaluatorCompanyId === el.id) {
                options.push({
                    id: el.id,
                    value: el.name,
                    label: el.name
                });
            }
        });
        setAuditorComboOptions(options);
    }

    useEffect(() => {
        const auditorCompanyById = auditorComboOptions.filter((x) => x.id === formValues.EvaluatorCompanyId)
        setAuditorComboSelected(auditorCompanyById)
    }, [auditorComboOptions])

    useEffect(() => {
        auditorComboSelected.length > 0 && getAuditorUsersByAudtiorCompanyId(auditorComboSelected[0].id)
    }, [auditorComboSelected])    

    const getAuditorUsersByAudtiorCompanyId = (id) => {
        dispatch(startRequest(getAuditorUsersByAuditorCompanyIdParams(id, getAuditorUsersByAuditorCompanyIdParamsSuccess)));
    }

    const getAuditorUsersByAuditorCompanyIdParamsSuccess = (data) => {
        let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: `${el.name} ${el.surnames}`,
                surnames:el.surnames,
                fullName:el.fullName,
                email:el.email,
                phoneNumber:el.phoneNumber,
                isAuditorCoordinator:el.isAuditorCoordinator,
                auditorCompanyId:el.auditorCompanyId,
                auditorCompanyName:el.auditorCompanyName,
            });
        });
        setAuditorsByCompanyComboOptions(options)
    }

    useEffect(() => {
        companyJointEvaluationSelected && updateJointEvaluationCompany()
    }, [companyJointEvaluationSelected])
    
    const updateJointEvaluationCompany = () => {
        if(jointEvaluation) {
            setFormValues({
                ...formValues,
                CompanyParentId: companyJointEvaluationSelected.id,
                IsCombined: true
            });
        }
    }
    
    useEffect(() => {
        if(!jointEvaluation) {
            setFormValues({
                ...formValues,
                CompanyParentId: '',
                IsCombined: false
            });
            setCompanyJointEvaluationSelected(null);
        }
    }, [jointEvaluation])

    useEffect(() => {
        firstLoad && getEvaluatorsListForData()
    }, [auditorsByCompanyComboOptions])
    
    const getEvaluatorsListForData = () => {
        const dataFinal = formValues.EvaluatorsListData.map((item) => {
            const data = auditorsByCompanyComboOptions.filter((el) => el.id === item );
            return data[0];
        });
        
        setFormValues({
            ...formValues,
            EvaluatorsList: dataFinal ? dataFinal : []
        });
        setEvaluatorDataComboSelected(dataFinal ? dataFinal[0] : [])
    }    

    useEffect(() => {
        firstLoad && getCompaniesJointEvaluation()
    }, [firstLoad])

    const getCompaniesJointEvaluation = () => {
        //call companyCombo
        dispatch(startRequest(getNotCombinedClientCompanies(getNotCombinedClientCompaniesSuccess)))
    }

    const getNotCombinedClientCompaniesSuccess = (data) => {
        let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });
        options = orderComboByText(options);
        setCompaniesJointEvaluation(options);
        
        if(formValues.CompanyParentId) {
            setCompanyJointEvaluationSelected(options.filter((el) => el.id === formValues.CompanyParentId)[0])
        }
    }

    const addEvaluatorToList = () => {
        let checkExistInList = [];
        if(formValues.EvaluatorsList.length > 0)
            checkExistInList = formValues.EvaluatorsList.filter((el) => el.id === auditorsByCompanyComboSelected.id);

        if( checkExistInList.length === 0 ) {
            const evaluatorsList = formValues.EvaluatorsList;
            evaluatorsList.push(auditorsByCompanyComboSelected);
    
            setFormValues({
                ...formValues,
                EvaluatorsList: evaluatorsList,
                EvaluatorsNumber: evaluatorsList.length
            });
        }

        setEvaluatorDataComboSelected(auditorsByCompanyComboSelected);
    }

    const removeEvaluatorToList = () => {
        let evaluatorsList = [];
        
        formValues.EvaluatorsList.forEach((el) => {
            if(el.id !== evaluatorDataComboSelected.id)
            evaluatorsList.push(el);
        });

        setFormValues({
            ...formValues,
            EvaluatorsList: evaluatorsList,
            EvaluatorsNumber: evaluatorsList.length
        });

        setEvaluatorDataComboSelected(evaluatorsList.length > 0 ? evaluatorsList[0] : []);
    }

    const getIsPrincipalCompany = (data) => {
        let show = true;

        show = !data.evaluationData.companyParentId ? true : false;

        return show;
    }

    const getShowEvaluatorForm = (data, companyId) => {
        let show = true;

        show = !data.evaluationData.companyParentId ? true : false; // False es franquicia
        
        if( userRol === 'Company' ) {
            if(!checkIsPrincipalOneCompany(userLogged, companyId)) {
                show = false;
            } else {
                show = !data.evaluationData.companyParentId ? true : false;
            }
        }

        return show;
    }

    //Set Auditor company Combo
    useEffect(() => {
        //Set Auditor company Combo
        if(formValues.EvaluatorCompanyId){
            const auditorCompanyById = auditorComboOptions.filter((x) => x.id === formValues.EvaluatorCompanyId)
            setAuditorComboSelected(auditorCompanyById)
        }
    }, [formValues.EvaluatorCompanyId])


    useEffect(() => {
        if(formValues.BudgetState === 'Aceptado' || (formValues.BudgetState === 'Pendiente' && userRol === 'Company')){
            setDisableDataBlocks(false)
        }else{
            setDisableDataBlocks(true)
        }
    }, [formValues.BudgetState])

    const disableByRoll = () => {
        let disabled = false;
        if(userRol === 'Company' && formValues.AllowAdminSelection || formValues.BudgetState === 'Pendiente') {
            disabled = true
            return disabled;
        }
    }

    const uploadFile = (e, type, uploadForce = false) => {
        let data = { File: e.target.files[0], RequestId: requestId };
		e.target.value = null;
        if (type === 'requestBudgetFile') {
            setFormValues({
                ...formValues,
                BudgetFileName: data.File.name
            })
            dispatch(startRequest(postBudgetMediaParams(data, postBudgetMediaParamsSuccess)))
        }
        if (type === 'contractsAndAnnexesFile') {
            setFormValues({
                ...formValues,
                ContractsAndAnnexesFileName: data.File.name,
            })            
            data = {
                ...data,
                ForceUpload: uploadForce
            }
            dispatch(startRequest(postContractsMediaParams(data, postContractsMediaParamsSuccess)))
            dispatch(closeModal());
        }
        if (type === 'sealFile') {
            setFormValues({
                ...formValues,
                SealFileName: data.File.name
            })
            dispatch(startRequest(postSealMediaParams(data, postSealMediaParamsSuccess)))
        }
        if (type === 'evaluationFile') {
            setFormValues({
                ...formValues,
                EvaluationFileName: data.File.name
            })
            dispatch(startRequest(postEvaluationMediaParams(data, postEvaluationMediaParamsSuccess)))
        }
        if (type === 'evaluationFile2') {
            setFormValues({
                ...formValues,
                EvaluationFileName2: data.File.name
            })
            dispatch(startRequest(postEvaluationPuntuationsMediaParams(data, postEvaluationPuntuationsMediaParamsSuccess)))
        }
    }

    const postBudgetMediaParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            budgetMedia: {
                id: data.id,
                name: data.name,
                url: data.url
            }
        })
        dispatch(showFixedLabel("Archivo subido correctamente", 'success'));
        getRequestDetail()
    }
    const postContractsMediaParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            contractsMedia: {
                id: data.id,
                name: data.name,
                url: data.url
            }
        })
        dispatch(showFixedLabel("Archivo subido correctamente", 'success'));
        getRequestDetail()
    }
    const postSealMediaParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            sealMedia: {
                id: data.id,
                name: data.name,
                url: data.url
            }
        })
        dispatch(showFixedLabel("Archivo subido correctamente", 'success'));
        getRequestDetail()
    }

    const postEvaluationMediaParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            evaluationMedia: {
                id: data.id,
                name: data.name,
                url: data.url
            }
        })
        dispatch(showFixedLabel("Archivo subido correctamente", 'success'));
        getRequestDetail()
    }
    
    const postEvaluationPuntuationsMediaParamsSuccess = (data) => {
        setFilesData({
            ...filesData,
            evaluationMedia2: {
                id: data.id,
                name: data.name,
                url: data.url
            }
        })
        dispatch(showFixedLabel("Archivo subido correctamente", 'success'));
        getRequestDetail()
    }

    const colorStates = (state) => {
        let color = '#ABD268';
        switch (state) {
            case 'Aprobado':
                color = '#ABD268'
                break;
            case 'Aceptado':
                color = '#ABD268'
                break;
            case 'Concedido':
                color = '#ABD268'
                break;
            case 'Validado':
                color = '#ABD268'
                break;
            case 'Pendiente':
                color = '#F8D750'
                break;
            case 'Rechazado':
                color = '#DC0513'
                break;
            case 'Completado':
                color = '#ABD268'
            default:
                break;
        }
        return color;
    }
    const colorEvaluationStates = (state) => {
        let iconColor = '#ABD268';
        switch (state) {
			case 'Pendiente informe':
				iconColor = '#F8D750'
				break;
            case 'Pendiente de validar':
				iconColor = '#F8D750'
				break;
			case 'Completado':
				iconColor = '#ABD268'
				break;
			case 'Sin fecha de visita':
				iconColor = '#28ABB0'
				break;
			case 'Sin entidad':
				iconColor = '#C10411'
				break;
            case 'Pendiente de autorización':
                iconColor = '#E67309'
                break;
			default:
				break;
		}
        return iconColor;
    }
    const colorContractStates = (state) => {
        let iconColor = '#ABD268';
        switch (state) {
			case "c1eb08e7-d5df-4927-8f58-9841984f459c": //'Pendiente subida'
				iconColor = '#F8D750'
				break;
            case "117014a4-9f49-49dc-8a9e-f7ddded5d565": // Pendiente firma empresa
				iconColor = '#F8D750'
				break;
			case "a1da1b64-8801-4ea2-b4c9-65e868f25d13": // Pendiente firma Madrid Excelente'
				iconColor = '#ABD268'
				break;
			case "e78a6020-4a57-45ac-aa96-f691a48ad8d8": //'Firmado'
				iconColor = '#28ABB0'
				break;			
			default:
				break;
		}
        return iconColor;
    }
    const colorStatesImprovementPlans = (state) => {
        let iconColor = '#ABD268';
        switch (state) {
			case "d8f89a86-6716-45ff-8211-9a93572a554b": //'Pendiente'
				iconColor = '#DC0513'
				break;
            case "c06c4f1a-cb83-46bb-ad82-18c700875e61": // Completado
				iconColor = '#ABD268'
				break;		
			default:
				break;
		}
        return iconColor;
    }

    const removeSecondVisitDate = (e) => {
        setFormValues({
            ...formValues,
            SecondVisitingDate: null
        })
        setAddSecondVisitDate(false)
    }

    const authorizeModalPopUp = (requestId, action) => {
        const optionValues = {
            id: 'requestDetailModal',
            dataTestId: 'requestDetailModal',
            cssClass: 'modal-header__transparent',
            title: t(action)
        };

        const options = {
            ...optionValues,
            body: (
                <RequestDetailModal
					requestId={requestId}
					action={action}
					handleSubmit={getRequestDetail}
					showFixedLabel={showFixedLabel}
                    isPrincipalCompany={isPrincipalCompany}
                    requestType={formValues.requestType}
				/>
            )
        };
        dispatch(openModal(options));
    }

    //Solicitud block
    const handleAuthorizeRequest = (e) => {
        authorizeModalPopUp(requestId, 'ApproveRequest')
    }

    const handleRejectRequest = (e) => {
        authorizeModalPopUp(requestId, 'RejectRequest')
    }

    const handleAuthorizeRenewal = (e) => {
        const data = {
            requestId: requestId,
            numEmployees: numberEmployees,
            revenueVolume: revenueVolumen
        }
        authorizeModalPopUp(data, 'ApproveRenewal')
    }

    const handleRejectRenewal = (e) => {
        authorizeModalPopUp(requestId, 'RejectRenewal')
    }

    //Presupuesto Block
    const handleAuthorizeBudgetByCompany = (e) => {
        authorizeModalPopUp(requestId, 'AcceptBudget')
    }

    const handleRejectBudgetByCompany = (e) => {
        authorizeModalPopUp(requestId, 'RejectBudget')
    }

    //Cuestionario autoevalucación block
    const handleAuthorizeQuestionnaire = (e) => {
        authorizeModalPopUp(requestId, 'ValidateQuestionnaire')
    }

    const handleRejectQuestionnaire = (e) => {
        authorizeModalPopUp(requestId, 'RejectQuestionnaire')
    }

    const handleOnClickDownloadExcel = (isAuditor = false) => {
        dispatch(startRequest(getExcelAutoEvaluationDataBase64Params(requestId, isAuditor, getExcelAutoEvaluationDataParamsSuccess)))
    }

    const getExcelAutoEvaluationDataParamsSuccess = (resp) => {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${resp}`;
        const downloadLink = document.createElement("a");
        console.log(linkSource);
        downloadLink.href = linkSource;
        downloadLink.download = 'cuestionario.xlsx'
        downloadLink.target = "_blank";
        downloadLink.click();
    }

    //Informe de evaluación Block
    const handleApproveEvaluation = (e) => {
        authorizeModalPopUp(requestId, 'ApproveEvaluation')
    }

    //Botón Guardar request
    const handleSubmitRequestDetail = (e) => {
        e.preventDefault()
        const auditorListOnlyIds = formValues.EvaluatorsList.map((el) => {
            return el.id;
        });

        const sealConcessionDateFinal = new Date(formValues.SealConcessionDate);
        sealConcessionDateFinal.setTime(sealConcessionDateFinal.getTime() - sealConcessionDateFinal.getTimezoneOffset()*60*1000);

        const data = {
            requestId: requestId,
            fileNumber: formValues.FileNumber,
            companySocialName: formValues.SocialName,
            companyCommercialName: formValues.ComercialName,
            contactName: formValues.ContactName,
            contactSurnames: formValues.ContactSurnames,
            contactEmail: formValues.ContactEmail,
            contactPhoneNumber: formValues.ContactPhone,
            firstVisitingDate: formValues.FirstVisitingDate,
            secondVisitingDate: formValues.SecondVisitingDate,
            concessionDate: formValues.SealConcessionDate ? sealConcessionDateFinal : null,
            companyAuditorId: auditorComboSelected ? auditorComboSelected.id : '',
            numberOfAuditors: formValues.EvaluatorsNumber,
            allowAdminSelection: formValues.AllowAdminSelection,
            auditorsIdsList: auditorListOnlyIds,
            isCombined: formValues.IsCombined,
            companyParentId: formValues.CompanyParentId
        }

        dispatch(startRequest(postSaveDetail(data, postSaveDetailSuccess)))
    }
    const postSaveDetailSuccess = (data) => {
        dispatch(showFixedLabel('Guardado correctamente', 'success'))
        getRequestDetail();
    }

    const handleOnClickApplication = () => {
        history.push({
            pathname: ROUTE.REQUESTID.replace(':id', requestId),
            state: {
                requestId
            }
        })
    }

    const onHandleChangeAuditorChange = (value) => {
        setAuditorComboSelected(value)
        setEvaluatorDataComboSelected([])
        setFormValues({
            ...formValues,
            EvaluatorsNumber: 0,
            FirstVisitingDate: null,
            SecondVisitingDate: null,
            EvaluatorsListData: [],
            EvaluatorsList: []
        })
    }

    const handleOnClickImprovementPlans = (improvementPlanId) => {
        history.push({
            pathname: ROUTE.IMPROVEMENTPLANS.replace(':id', requestId),
            state: {
                requestId: requestId,
                autoevaluationId: formValues.AutoevaluationId,
                improvementPlanId: improvementPlanId
            }
        })
    }

    const showDateLabel = () => {
        switch (formValues.requestType) {
            case 0:
                return (t('RequestDateCreate'))
                break;
            case 1:
                return (t('TrackingDateCreate'))
                break;
            case 2:
                return (t('RenewalDateCreate'))
                break;
        
            default:
                break;
        }
    }

    const deregisterModalPopUp = () => {
        const optionValues = {
            id: 'deregisterCompanyModal',
            dataTestId: 'deregisterCompanyModal',
            cssClass: 'modal-header__transparent',
            title: t('DeregisterCompany')
        };

        const options = {
            ...optionValues,
            body: (
                <RequestDetailModal
					requestId={companyId}
					action={'DeregisterCompany'}
					handleSubmit={getRequestDetail}
					showFixedLabel={showFixedLabel}
                    requestType={formValues.requestType}
				/>
            )
        };
        dispatch(openModal(options));
    }    

    const activateCompany = () => {
        dispatch(startRequest(postReactivateCompany(companyId, postReactivateCompanySuccess)))
    }

    const postReactivateCompanySuccess = () => {
        getRequestDetail();
    }

    const changeToRenewalModalPopUp = () => {
        const optionValues = {
            id: 'changeToRenewalModal',
            dataTestId: 'changeToRenewalModal',
            cssClass: 'modal-header__transparent',
            title: t('ChangeToRenewalRequest')
        };

        const options = {
            ...optionValues,
            body: (
                <RequestDetailModal
                    requestId={requestId}
                    action={'ChangeToRenewal'}
                    handleSubmit={getRequestDetail}
                    showFixedLabel={showFixedLabel}
                    requestType={formValues.requestType}
                />
            )
        };
        dispatch(openModal(options));
    }

    return (
        <div className='request-detail '>
            <FixedLabel parentType="general" />
            <form className='request-detail__form' >
                <div className='request-detail__heading'>
                    <div className='heading-logo-container '>
                        <h1 className='logo-wrapper'>
                            <img className='logo-img' src={Logo} alt="logo madrid excelente" />
                        </h1>
                        <span className='sr-only' aria-labelledby='returnButton'>Volver atrás</span>
                        <button className='return-wrapper'
                            id='returnButton'
                            role='navigation'
                            onClick={() => {
                                //history.goBack()
                                userRol === 'Company' ? history.push('/solicitudes') : history.push('/empresas')
                            }}
                        >
                            <span className='material-icons' aria-hidden={true}>arrow_back</span>
                            <span className='return-link'>
                                {t('GoBack')}
                            </span>
                        </button>
                    </div>
                    <div className='user-container'>
                        <div className='user-container-wrapper'>
                            <div className='help-wrapper' >
                                <a className='return-wrapper' href='https://www.madridexcelente.com/' role='button'>
                                    <span className='material-icons' aria-hidden={true}>arrow_back</span> 
                                    <span className='return-link'>
                                        Volver a la web de Madrid Excelente
                                    </span>
                                </a>
                            </div> 
                            <div className='user-wrapper'>
                                <span className='user-text'>{userLogged ? userLogged.name : null}</span>
                            </div>
                        </div>
                        {
                            userRol === 'Auditor' &&
                                <div className="btn-deregister-wrapper">
                                    {
                                        firstLoad &&
                                            <Button 
                                                dataTestID={'btnIsCompany'}
                                                id={'btnIsCompany'}
                                                btnText={ isPublicCompany ? t('Public') : t('Private') }
                                                btnType="button"
                                                btnClass={ `btn-base_success` }
                                                onHandleBtnClick={ () => {} }
                                                icon={ 'location_city' }
                                                isDisabled={true}
                                            />
                                    }
                                </div>
                        }
                        {
                            userRol === 'Admin' &&
                                <div className="btn-deregister-wrapper">
                                    {
                                        firstLoad &&
                                            <Button 
                                                dataTestID={'btnIsCompany'}
                                                id={'btnIsCompany'}
                                                btnText={ isPublicCompany ? t('Public') : t('Private') }
                                                btnType="button"
                                                btnClass={ `btn-base_success` }
                                                onHandleBtnClick={ () => {} }
                                                icon={ 'location_city' }
                                                isDisabled={true}
                                            />
                                    }
                                    {
                                        firstLoad && formValues.requestType === 0 &&
                                            <Button 
                                                dataTestID={'btnChangeToRenewalRequest'}
                                                id={'btnChangeToRenewalRequest'}
                                                btnText={ t('ChangeToRenewalRequest') }
                                                btnType="button"
                                                btnClass={ `btn-base_warning` }
                                                onHandleBtnClick={ () => changeToRenewalModalPopUp() }
                                                icon={ 'edit' }
                                            />
                                    }
                                    {
                                        formValues.DeregisterCompanyStatusId != '' && formValues.DeregisterCompanyStatusId != "98240a48-96c7-4d5a-9abf-1b1110a2099f" &&
                                            <div className={ `badget_outline ${formValues.DeregisterCompanyStatusId === '2ed45da7-5b24-4f61-b194-5ba0e168455b' ? 'badget_outline-success' : 'badget_outline-danger'}`}>{ formValues.DeregisterCompanyStatusName }</div>
                                    }
                                    {
                                        formValues.DeregisterCompanyStatusId != ''
                                            ? formValues.DeregisterCompanyStatusId === "98240a48-96c7-4d5a-9abf-1b1110a2099f" || formValues.DeregisterCompanyStatusId === "2ed45da7-5b24-4f61-b194-5ba0e168455b"
                                                ? <Button 
                                                    dataTestID={'btnDeregisterCompany'}
                                                    id={'btnDeregisterCompany'}
                                                    btnText={ t('DeregisterCompany') }
                                                    btnType="button"
                                                    btnClass={ `btn-base_danger` }
                                                    onHandleBtnClick={ () => deregisterModalPopUp() }
                                                    icon={ 'delete' }
                                                />
                                                : <Button 
                                                    dataTestID={'btnRegisterCompany'}
                                                    id={'btnRegisterCompany'}
                                                    btnText={ t('RegisterCompany') }
                                                    btnType="button"
                                                    btnClass={ `btn-base_success` }
                                                    onHandleBtnClick={ () => activateCompany() }
                                                    icon={ 'check' }
                                                />
                                            : null
                                    }
                                </div>
                        }
                    </div>
                </div>                
                <div className='request-detail__content me-container'>
                    <div className="request-detail__content__header flex-container">
                        <div className="request-detail__content__header__left flex-container">
                            <div className='fields-wrapper date'>
                                <label className='input-label'>{showDateLabel()}</label>
                                <div className='row-wrapper'>
                                    <div className='date-wrapper '>
                                        <DatePickerComponent
                                            showTodayButton={false}
                                            allowEdit={false}
                                            openOnFocus={true}
                                            isRequired={true}
                                            value={formValues.RequestDate}
                                            disabled={true}
                                            placeholder={showDateLabel()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-container space-between">
                                <div className="form-wrapper large">
                                    <Input
                                        inputName="txtFileName"
                                        inputType="text"
                                        inputLabel={t('ExpNumber')}
                                        inputID="tradename"
                                        isDisabled={userRol === 'Admin' ? false : true}
                                        showLabel={true}
                                        value={formValues.FileNumber}
                                        inputPlaceholder={t('ExpNumber')}
                                        onHandleChange={(e) => setFormValues({...formValues, FileNumber: e.target.value})}
                                    />
                                </div>
                                <div className="form-wrapper large">
                                    {
                                        userRol !== 'Company' && parentCompanyName &&
                                            <Input
                                                inputName="companyParentName"
                                                inputType="text"
                                                inputLabel={t('companyParentName')}
                                                inputID="companyParentName"
                                                isDisabled={true}
                                                showLabel={true}
                                                value={parentCompanyName}
                                                inputPlaceholder={t('companyParentName')}
                                                onHandleChange={(e) => {}}
                                            />
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            userRol === 'Admin' &&
                            <>         
                                    <div className="request-detail__content__header__right flex-container">
                                        <div className="additional-buttons">
                                            <Button 
                                                dataTestID={'btnAdditionalData'}
                                                id={'btnAdditionalData'}
                                                btnText={ showAdditionalData ? t('GeneralData') : t('AdditionalData') }
                                                btnType="button"
                                                btnClass={ `btn-base_normal ${ showAdditionalData ? 'btn-icon-rotate' : 'btn-icon-unrotate' }` }
                                                onHandleBtnClick={ () => { setShowAdditionalData(!showAdditionalData) } }
                                                icon={ 'forward' }
                                            />
                                        </div>
                                        <fieldset className='field-container' >
                                            <div className='column-wrapper'>
                                                <div className='form-input-group radio-group'>
                                                    <div className='radio-group__label' >{t('JointEvaluation')}</div>
                                                    <div className='radio-group__inputs'>
                                                        <Radio
                                                            id='jointEvaluationYes'
                                                            label="Sí"
                                                            name='typeJointEvaluation'
                                                            value={formValues.IsCombined ? true : false}
                                                            isDisabled={false}
                                                            handleChecked={jointEvaluation === null ? false : jointEvaluation ? true : false}
                                                            handleChange={() => setJointEvaluation(true)}
                                                        />
                                                        <Radio
                                                            id='jointEvaluationNo'
                                                            label="No"
                                                            name='typeJointEvaluation'
                                                            value={formValues.IsCombined ? false : true}
                                                            isDisabled={false}
                                                            handleChecked={jointEvaluation === null ? false : jointEvaluation ? false : true}
                                                            handleChange={() =>  setJointEvaluation(false) }
                                                        /> 

                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                jointEvaluation ? (
                                                    <div className='column-wrapper'>
                                                        <div className='form-input-group select-group no-label'>
                                                            <Combo 
                                                                id="NameMainCompany"
                                                                data-testid="NameMainCompany"
                                                                defaultInputValue=""
                                                                options={companiesJointEvaluation}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                className="comboBox"
                                                                handleChange={(e) => setCompanyJointEvaluationSelected(e)}
                                                                inputValue={companyJointEvaluationSelected}
                                                                isMultiple={false}
                                                                comboPlaceholder={t('NameMainCompany')}
                                                            /> 
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                        </fieldset>
                                    </div>
                                </>
                        }
                    </div>


                    <div className="flex-container space-between">
                        <div className="form-wrapper large">
                            <Input
                                inputName="txtSocialName"
                                inputType="text"
                                inputLabel={t('Razón social')}
                                inputID="socialname"
                                isDisabled={userRol === 'Auditor' ? true : false}
                                showLabel={true}
                                value={formValues.SocialName}
                                inputPlaceholder={t('Razón social')}
                                onHandleChange={(e) => setFormValues({...formValues, SocialName: e.target.value})}
                            />
                        </div>
                        <div className="form-wrapper large">
                            <Input
                                inputName="txtComercialName"
                                inputType="text"
                                inputLabel={t('TradeName')}
                                inputID="socialname"
                                isDisabled={userRol === 'Auditor' ? true : false}
                                showLabel={true}
                                value={formValues.ComercialName}
                                inputPlaceholder={t('TradeName')}
                                onHandleChange={(e) => setFormValues({...formValues, ComercialName: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className="flex-container space-between">
                        <div className="form-wrapper middle">
                            <Input
                                inputName="ContactName"
                                inputType="text"
                                inputLabel={t('ContactName')}
                                inputID="ContactName"
                                isDisabled={userRol === 'Auditor' ? true : false}
                                showLabel={true}
                                value={formValues.ContactName}
                                inputPlaceholder={t('ContactName')}
                                onHandleChange={(e) => setFormValues({...formValues, ContactName: e.target.value})}
                            />
                        </div>
                        <div className="form-wrapper middle">
                            <Input
                                inputName="ContactSurnames"
                                inputType="text"
                                inputLabel={t('ContactSurnames')}
                                inputID="ContactSurnames"
                                isDisabled={userRol === 'Auditor' ? true : false}
                                showLabel={true}
                                value={formValues.ContactSurnames}
                                inputPlaceholder={t('ContactSurnames')}
                                onHandleChange={(e) => setFormValues({...formValues, ContactSurnames: e.target.value})}
                            />
                        </div>
                        <div className="form-wrapper middle">
                            <Input
                                inputName="ContactEmail"
                                inputType="text"
                                inputLabel={t('ContactEmail')}
                                inputID="ContactEmail"
                                isDisabled={userRol === 'Auditor' ? true : false}
                                showLabel={true}
                                value={formValues.ContactEmail}
                                inputPlaceholder={t('ContactEmail')}
                                onHandleChange={(e) => setFormValues({...formValues, ContactEmail: e.target.value})}
                            />
                        </div>
                        <div className="form-wrapper middle">
                            <Input
                                inputName="ContactPhone"
                                inputType="number"
                                inputLabel={t('ContactPhone')}
                                inputID="ContactPhone"
                                isDisabled={userRol === 'Auditor' ? true : false}
                                showLabel={true}
                                value={formValues.ContactPhone}
                                inputPlaceholder={t('ContactPhone')}
                                onHandleChange={(e) => setFormValues({...formValues, ContactPhone: e.target.value})}
                            />
                        </div>
                    </div>
                </div>
                {/* <div className='separator'></div> */}
                {
                    firstLoad &&
                        <div className='request-detail__content me-container mb-5'>
                            {
                                !showAdditionalData                                
                                    ? userRol === 'Company' && formValues.requestType === 1 && !checkIsPrincipalOneCompany(userLogged, companyId)
                                        ? null
                                        : userRol === 'Auditor' && !showEvaluatorForm
                                            ? null
                                            :
                                        <>
                                            <div className="flex-container flex-row space-between">
                                                <div className='left-wrapper'>                                                
                                                    {
                                                        formValues.requestType !== 1 
                                                            ? userRol === 'Admin' || userRol === 'Company' && checkIsPrincipalOneCompany(userLogged, companyId)
                                                                    ? <div className='data-block'>
                                                                        <RequestApplication 
                                                                            userRol={userRol}
                                                                            formValues={formValues}
                                                                            colorStates={colorStates}
                                                                            handleOnClick={handleOnClickApplication}
                                                                            handleAuthorizeRequest={handleAuthorizeRequest}
                                                                            handleRejectRequest={handleRejectRequest}
                                                                            numberEmployees={numberEmployees}
                                                                            setNumberEmployees={setNumberEmployees}
                                                                            revenueVolumen={revenueVolumen}
                                                                            setRevenueVolumen={setRevenueVolumen}
                                                                            handleAuthorizeRenewal={handleAuthorizeRenewal}
                                                                            handleRejectRenewal={handleRejectRenewal}
                                                                        />
                                                                    </div>
                                                                : null
                                                            : null
                                                    }                                                    
                                                    {
                                                        formValues.requestType !== 1 
                                                            ? userRol === 'Admin' || userRol === 'Company' && checkIsPrincipalOneCompany(userLogged, companyId)
                                                                ? <div className='data-block'>
                                                                    <RequestBudget 
                                                                        userRol={userRol}
                                                                        formValues={formValues}
                                                                        filesData={filesData}
                                                                        colorStates={colorStates}
                                                                        disableDataBlocks={disableDataBlocks}
                                                                        handleAuthorizeBudgetByCompany={handleAuthorizeBudgetByCompany}
                                                                        handleRejectBudgetByCompany={handleRejectBudgetByCompany}
                                                                        uploadFile={uploadFile}
                                                                    />  
                                                                </div>
                                                                : null
                                                            : null
                                                    }                                                
                                                    {   
                                                        formValues.requestType === 1 && userRol === 'Company' && formValues.QuestionnaireState !== "Validado"
                                                            ? null
                                                            : showQuestionnarie
                                                                ? <div className='data-block'>
                                                                    <RequestQuestionnaire 
                                                                        userRol={userRol}
                                                                        formValues={formValues}
                                                                        colorStates={colorStates}
                                                                        disableDataBlocks={disableDataBlocks}
                                                                        handleAuthorizeQuestionnaire={handleAuthorizeQuestionnaire}
                                                                        handleRejectQuestionnaire={handleRejectQuestionnaire}
                                                                        handleOnClickDetail={() => history.push({
                                                                            pathname: ROUTE.SELFASSESSMENTID.replace(':id', requestId),
                                                                            state: {
                                                                                id: requestId,
                                                                                socialName: formValues.SocialName
                                                                            }
                                                                        })}
                                                                        handleOnClickDownloadExcel={handleOnClickDownloadExcel}
                                                                        isEvaluationPublic={isEvaluationPublic}
                                                                    />
                                                                </div>
                                                                : null
                                                    }
                                                    {
                                                        formValues.requestType !== 1 
                                                            ? userRol === 'Admin' || userRol === 'Company' && checkIsPrincipalOneCompany(userLogged, companyId)
                                                                ? <div className='data-block'>
                                                                    <RequestContracts 
                                                                        userRol={userRol}
                                                                        formValues={formValues}
                                                                        isPrincipalCompany={isPrincipalCompany}
                                                                        filesData={filesData}
                                                                        colorContractStates={colorContractStates}
                                                                        disableDataBlocks={disableDataBlocks}
                                                                        uploadFile={uploadFile}
                                                                        onHandleChange={
                                                                            (e) => {
                                                                                setFormValues({
                                                                                    ...formValues,
                                                                                    SealConcessionDate: e.value
                                                                                })
                                                                            }
                                                                        }
                                                                    />  
                                                                </div>
                                                                : null
                                                            : null
                                                    }
                                                    {
                                                        userRol === 'Admin' || userRol === 'Company' && checkIsPrincipalOneCompany(userLogged, companyId)
                                                            ? <div className='data-block'>
                                                                <RequestSeal 
                                                                        userRol={userRol}
                                                                        formValues={formValues}
                                                                        filesData={filesData}
                                                                        colorStates={colorStates}
                                                                        disableDataBlocks={disableDataBlocks}
                                                                        uploadFile={uploadFile}
                                                                        isPrincipalCompany={isPrincipalCompany}
                                                                        onHandleChange={
                                                                            (e) => {
                                                                                setFormValues({
                                                                                    ...formValues,
                                                                                    SealConcessionDate: e.value
                                                                                })
                                                                            }
                                                                        }
                                                                    />  
                                                                    
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                                {
                                                    showEvaluatorForm
                                                        ? <div className='right-wrapper'>
                                                            <div className='data-block'>
                                                                <div className='title-wrapper'>
                                                                    <h2 className='title'>{t('EvaluationCompany')}</h2>
                                                                </div>
                                                                {
                                                                    userRol === 'Admin' || userRol === 'Company' && checkIsPrincipalOneCompany(userLogged, companyId) && formValues.requestType === 0
                                                                    ? (
                                                                        <div className='content-wrapper'>
                                                                            <div className='form-input-group select-group no-label'>
                                                                                <Combo
                                                                                    id="AuditorComboSelected"
                                                                                    data-testid="AuditorComboSelected"
                                                                                    defaultInputValue=""
                                                                                    options={auditorComboOptions}
                                                                                    isSearchable={false}
                                                                                    isClearable={true}
                                                                                    handleChange={(e) => onHandleChangeAuditorChange(e)}
                                                                                    inputValue={auditorComboSelected}
                                                                                    isMultiple={false}
                                                                                    comboPlaceholder={t('Seleccionar')}
                                                                                    isDisabled={
                                                                                        disableDataBlocks ||
                                                                                        disableByRoll() 
                                                                                        //formValues.QuestionnaireState === 'Validado'
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            {
                                                                                auditorComboSelected.length === 0 ? (

                                                                                    <div className='checks-wrapper__item no-margin'>
                                                                                        <Checkbox
                                                                                            inputID={'acceptMailing'}
                                                                                            dataTestID={'acceptMailing'}
                                                                                            inputName={'acceptMailing'}
                                                                                            inputLabel={t('Permitir selección a Madrid Excelente')}
                                                                                            //inputChecked={letAdminChooseAuditorCompany}
                                                                                            inputChecked={formValues.AllowAdminSelection}
                                                                                            onHandleChange={(e) => {
                                                                                                //setletAdminChooseAuditorCompany(e.target.checked)
                                                                                                setFormValues({
                                                                                                    ...formValues,
                                                                                                    AllowAdminSelection: e.target.checked
                                                                                                })
                                                                                            }}
                                                                                            withLink={false}
                                                                                            isDisabled={disableDataBlocks || userRol === 'Admin' || formValues.BudgetState === 'Pendiente'}
                                                                                        />
                                                                                    </div>
                                                                                ): null
                                                                            }
                                                                        </div>
                                                                    ) : (
                                                                        <div className='content-wrapper'>
                                                                            <div>{auditorComboSelected.length > 0 ? auditorComboSelected[0].value : 'No hay empresa auditora seleccionada, consultar con el administrador'}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='data-block'>
                                                                <RequestVisitEvaluator 
                                                                    userRol={userRol}
                                                                    formValues={formValues}
                                                                    disableDataBlocks={disableDataBlocks}
                                                                    removeSecondVisitDate={removeSecondVisitDate}
                                                                    setAddSecondVisitDate={setAddSecondVisitDate}
                                                                    addSecondVisitDate={addSecondVisitDate}
                                                                    setFormValues={setFormValues}
                                                                />
                                                            </div>
                                                            {
                                                                userLogged.userData.auditorUserData?.isCoordinator
                                                                    ? <div className='data-block'>
                                                                        <RequestSelectEvaluator 
                                                                            userRol={userRol}
                                                                            formValues={formValues}
                                                                            disableDataBlocks={disableDataBlocks}
                                                                            removeSecondVisitDate={removeSecondVisitDate}
                                                                            setAddSecondVisitDate={setAddSecondVisitDate}
                                                                            addSecondVisitDate={addSecondVisitDate}
                                                                            setFormValues={setFormValues}
                                                                            auditorsByCompanyComboOptions={auditorsByCompanyComboOptions}
                                                                            auditorsByCompanyComboSelected={auditorsByCompanyComboSelected}
                                                                            setAuditorsByCompanyComboSelected={setAuditorsByCompanyComboSelected}
                                                                            addEvaluatorToList={addEvaluatorToList}
                                                                            
                                                                        />
                                                                    </div>
                                                                : null
                                                            }                                                        
                                                            <RequestDataEvaluator 
                                                                isAuditorCoordinator={userLogged.userData.auditorUserData?.isCoordinator}
                                                                formValues={formValues}
                                                                evaluatorDataComboSelected={evaluatorDataComboSelected}
                                                                setEvaluatorDataComboSelected={setEvaluatorDataComboSelected}
                                                                removeEvaluatorToList={removeEvaluatorToList}
                                                            />
                                                            {
                                                                userRol !== 'Company' 
                                                                    ? (
                                                                        <div className='data-block'>
                                                                            <RequestInformEvaluatorForm 
                                                                                userRol={userRol}
                                                                                formValues={formValues}
                                                                                filesData={filesData}
                                                                                uploadFile={uploadFile}
                                                                                disableDataBlocks={disableDataBlocks}
                                                                                colorEvaluationStates={colorEvaluationStates}
                                                                                handleApproveEvaluation={handleApproveEvaluation}
                                                                            />
                                                                        </div>
                                                                    ) 
                                                                    : formValues.EvaluationState === 'Completado' 
                                                                        ? (
                                                                            <div className='data-block'>
                                                                                <RequestInformEvaluator
                                                                                    formValues={formValues}
                                                                                    filesData={filesData}
                                                                                    colorEvaluationStates={colorEvaluationStates}
                                                                                />
                                                                            </div>
                                                                        ) 
                                                                        : null
                                                            }
                                                            {
                                                                // formValues.SealState === 'Concedido' && formValues.EvaluationState === 'Completado' 
                                                                formValues.EvaluationState === 'Completado' 
                                                                    ? (
                                                                        <div className='data-block'>
                                                                            <RequestImprovementPlans
                                                                                userRol={userRol}
                                                                                formValues={formValues}
                                                                                colorStatesImprovementPlans={colorStatesImprovementPlans}
                                                                                handleOnClickImprovementPlans={handleOnClickImprovementPlans}
                                                                            />
                                                                        </div>
                                                                    ) 
                                                                    : null
                                                            }
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            {
                                                formValues.RequestState !== 'Rechazado' ? (
                                                    <div className='btn-wrapper last-wrapper'>
                                                        <Button
                                                            btnText={t('Save')}
                                                            btnType="button"
                                                            btnClass="btn-base_normal "
                                                            dataTestID="SaveRequestDetailBtn"
                                                            id="SaveRequestDetailBtn"
                                                            onHandleBtnClick={(e) => handleSubmitRequestDetail(e)}
                                                            //isDisabled={userRol === 'Company' ? true : false}
                                                            isDisabled={false}
                                                        />
                                                    </div>

                                                ) : null
                                            }
                                        </>
                                    : <CompaniesAdditionalData
                                        companyId={companyId}
                                    />
                            }
                            
                        </div>
                }
            </form>
        </div>
    )
}


export default RequestDetail;