import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { Input, Button, Combo } from '@backoffice/components/FormComponents/index';
import { startRequest } from '@backoffice/actions/apiCall';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { 
	getRequestsParams, 
	postFilterRequests,
	getBudgetStatusParams,
	getClientCompaniesParams,
	getRequestTypeParams,
	getQuestionnaireStatusTypeParams,
	getBasicClientCompaniesForAuditorParams,
	getEvaluationStatusTypeParams,
	getContractStatusTypeParams,
	postReactivateRequest,
	getApplicantStatusTypeParams,
	getAuditorCompaniesParams,
	getAllEvaluators
} from './CompaniesCalls/CompaniesCalls'
import { ROUTE } from '../../../common/constants/routes';
import { GetSessionStorage } from '../../../common/utils/storageManager';
import './Companies.scss';
import HeaderPages from '../../../shared/HeaderPages';
import { orderComboByText } from '../../../common/utils/parseUtils';
import { Checkbox } from '../../../components/FormComponents';
import { showFixedLabel } from '../../../actions/fixedLabel';
import Paginator from '../../../components/Paginator/Paginator';
import { useDebounce } from '../../../hooks/useDebounce';

const Companies = () => {
	const grid = useRef(null);
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [currentPage, setCurrentPage] = useState(1)
	const [totalCount, setTotalCount] = useState(0)
	const itemPerPage = 12;
	
	const [filterDate, setFilterDate] = useState({
		StartDate: null,
		EndDate: null
	});
	const [filterConcessionDate, setFilterConcessionDate] = useState({
		StartDate: null,
		EndDate: null
	})

	//Combo 'client' companies
	const [companiesOptions, setCompaniesOptions] = useState([])
	const [companyComboSelected, setCompanyComboSelected] = useState()

	//Combo budgetStatus
	const [budgetOptions, setBudgetOptions] = useState([])
	const [budgetComboSelected, setBudgetComboSelected] = useState()

	//Combo requestType
	const [requestTypeOptions, setRequestTypeOptions] = useState([])
	const [requestTypeComboSelected, setRequestTypeComboSelected] = useState()

	//Combo questionnaireType
	const [questionnaireTypeOptions, setQuestionnaireTypeOptions] = useState([])
	const [questionnaireTypeComboSelected, setQuestionnaireTypeComboSelected] = useState()

	//Combo questionnaireType
	const [evaluationTypeOptions, setEvaluationTypeOptions] = useState([])
	const [evaluationTypeComboSelected, setEvaluationTypeComboSelected] = useState()

	//Combo questionnaireType
	const [contractsTypeOptions, setContractsTypeOptions] = useState([])
	const [contractsTypeComboSelected, setContractsTypeComboSelected] = useState()

	//Combo applicantStatusType
	const [applicantStatusTypeOptions, setApplicantStatusTypeOptions] = useState([])
	const [applicantStatusTypeComboSelected, setApplicantStatusTypeComboSelected] = useState()
	
	//Combo auditorCompany
	const [auditorCompanyOptions, setAuditorCompanyOptions] = useState([])
	const [auditorCompanyComboSelected, setAuditorCompanyComboSelected] = useState()

	//Combo auditorCompany
	const [evaluatorOptions, setEvaluatorOptions] = useState([])
	const [evaluatorSelected, setEvaluatorSelected] = useState()

	//Combo isPublic
	const isPublicOptions = [
		{
			id: 'all',
			label: 'Todas',
			value: null
		},
		{
			id: 'public',
			label: t('Public'),
			value: true
		},
		{
			id: 'private',
			label: t('Private'),
			value: false
		}
	]
	const [isPublic, setIsPublic] = useState(isPublicOptions)
	const [isPublicSelected, setIsPublicSelected] = useState()

	// Combo activeType
	const activeTypeOptions = [
		{
			id: 'all',
			label: 'Todas',
			value: null
		},
		{
			id: 'active',
			label: 'Activas',
			value: true
		},
		{
			id: 'desactive',
			label: 'No activas',
			value: false
		}
	]
	const [activeSelected, setActiveSelected] = useState(activeTypeOptions[0])

	// Combo improvementPlanStatus
	const improvementPlanStatusOptions = [
		{
			id: 'pending',
			label: t('Pending'),
			value: 0
		},
		{
			id: 'completed',
			label: t('Completed'),
			value: 1
		}
	]
	const [improvementPlanStatusSelected, setImprovementStatusSelected] = useState()
	// Check Have secondary companies
	const [checkHaveSecondaryCompanies, setCheckHaveSecondaryCompanies] = useState(false)

	useEffect(() => {
		//Combos calls
		const storage = GetSessionStorage('PROFILE_')

		if(storage)
            storage.userRoles[0].name === 'Auditor' ? getBasicClientCompaniesForAuditor(storage.userData.auditorUserData.companyId) : getClientCompanies();
		getBudgetStatus()
		getRequestType()
		getEvaluationsType()
		getContractsType()
		getQuestionnaireType()
		getApplicantStatusType()
		getAuditorCompanies()
		getEvaluators()
	}, [])
	
	const getClientCompanies = () => {
		dispatch(startRequest(getClientCompaniesParams(getClientCompaniesParamsSuccess)))
	}
	const getBasicClientCompaniesForAuditor = (id) => {
		dispatch(startRequest(getBasicClientCompaniesForAuditorParams(id, getClientCompaniesParamsSuccess)))
	}
	const getClientCompaniesParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name,
				isCompanyPrincipal: el.isCompanyPrincipal
            });
        });
		options = orderComboByText(options);
		setCompaniesOptions(options)
	}	
	const getRequestType = () => {
		dispatch(startRequest(getRequestTypeParams(getRequestTypeParamsSuccess)))
	}
	const getRequestTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.order,
                value: el.name,
                label: el.name
            });
        });
		setRequestTypeOptions(options)
	}	
	const getBudgetStatus = () => {
		dispatch(startRequest(getBudgetStatusParams(getBudgetStatusParamsSuccess)))
	}
	const getBudgetStatusParamsSuccess = (data) => {
		let options = [];
		data.forEach((el) => {
			options.push({
				id: el.id,
				value: el.name,
				label: el.name
			});
		});
		setBudgetOptions(options)
	}
	const getContractsType = () => {
		dispatch(startRequest(getContractStatusTypeParams(getContractStatusTypeParamsSuccess)))
	}
	const getContractStatusTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });
		setContractsTypeOptions(options)
	}
	const getEvaluationsType = () => {
		dispatch(startRequest(getEvaluationStatusTypeParams(getEvaluationStatusTypeParamsSuccess)))
	}
	const getEvaluationStatusTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });
		setEvaluationTypeOptions(options)
	}
	const getQuestionnaireType = () => {
		dispatch(startRequest(getQuestionnaireStatusTypeParams(getQuestionnaireStatusTypeParamsSuccess)))
	}
	const getQuestionnaireStatusTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });
		setQuestionnaireTypeOptions(options)
	}	
	const getApplicantStatusType = () => {
		dispatch(startRequest(getApplicantStatusTypeParams(getApplicantStatusTypeParamsSuccess)))
	}
	const getApplicantStatusTypeParamsSuccess = (data) => {
		let options = [];
        data.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });
		setApplicantStatusTypeOptions(options)
	}

	const getAuditorCompanies = () => {
		dispatch(startRequest(getAuditorCompaniesParams(getAuditorCompaniesParamsSuccess)));
	};

	const getAuditorCompaniesParamsSuccess = (response) => {
        let options = [];
        response.forEach((el) => {
            options.push({
                id: el.id,
                value: el.name,
                label: el.name
            });
        });

		setAuditorCompanyOptions(options);
	};

	const getEvaluators = () => {
		dispatch(startRequest(getAllEvaluators(getEvaluatorsSuccess)));
	};

	const getEvaluatorsSuccess = (response) => {
        let options = [];
        response.forEach((el) => {
            options.push({
                id: el.id,
                value: el.fullName,
                label: el.fullName
            });
        });

		setEvaluatorOptions	(options);
	};

	const [search, setSearch] = useState('');
	const searchDebounce = useDebounce(search);
	const [requests, setRequests] = useState();
	const [allRequests, setAllRequests] = useState();
	const [showExportExcelBtn, setShowExportExcelBtn] = useState(false)
	const [userLogedName, setUserLogedName] = useState('')
	const [isSuperAdmin, setIsSuperAdmin] = useState(false)
	const [isCompany, setIsCompany] = useState(false)
	const [isAuditor, setIsAuditor] = useState(false)
	const [firstCall, setFirstCall] = useState(true)

	useEffect(() => {
		getUserLoged()
	}, []);

	const getUserLoged = () =>{
		const storage = GetSessionStorage('PROFILE_')
		setUserLogedName(storage.name)
		if(storage) {
            storage.userRoles.map(el => {
                if(el.name === 'Admin') {
					setIsSuperAdmin(true)
					setIsCompany(false)
					setIsAuditor(false)
				} 
				if(el.name === 'Company') {
					setIsCompany(true)
					setIsSuperAdmin(false)
					setIsAuditor(false)
				}
				if(el.name === 'Auditor'){
					setIsAuditor(true)
					setIsCompany(false)
					setIsSuperAdmin(false)
				}
            })
        }
		
		getCompaniesRequests();
	}
	
	const getCompaniesRequests = () => {
		dispatch(startRequest(getRequestsParams((currentPage - 1) * itemPerPage, itemPerPage, getRequestsSuccess)));
	};

	const getRequestsSuccess = (response) => {
		setAllRequests(response.list);
		setRequests(response.list);
		setTotalCount(response.totalCount)
		setFirstCall(false)
	};

	// const GetDataBySearch = (data = [], searching = '') => {
	// 	const result = data.filter((element) => {
	// 		return (
	// 			element.companySocialName.toLowerCase().includes(searching.toLowerCase())
	// 		);
	// 	});
	// 	return result;
	// };

	// const filterRequests = () => {
	// 	if (search === '') {
	// 	 	setRequests(allRequests);
	// 	}
	// 	const newData = GetDataBySearch(allRequests, search);
	// 	if (newData === '') {
	// 		setRequests(allRequests);
	// 	}
	// 	setRequests(newData);
	// };

	const handleRedirectFromGrid = (id) => {
		const storage = GetSessionStorage('PROFILE_')
		const companyUser = storage.userRoles[0].name === 'Company'
		history.push({
			pathname: !companyUser ? ROUTE.REQUESTDETAIL.replace(':id', id) : ROUTE.SOLICITUDESID.replace(':id', id),
			state: {
				requestId: id
			}
		})
	}

	const handleActivateFromGrid = (id) => {
		dispatch(startRequest(postReactivateRequest(id, postReactivateRequestSuccess, postReactivateRequestError)));
	}

	const postReactivateRequestSuccess = (response) => {
		dispatch(showFixedLabel('Se ha reactivado correctamente', 'success'));
		getCompaniesRequests();
	}

	const postReactivateRequestError = (response) => {
		if(response.errors[0]?.errorMessageKey === 'RequestIsAlreadyActivated') {
			dispatch(showFixedLabel('No se ha podido reactivar, ya está activa', 'error'));
		} else if(response.errors[0]?.errorMessageKey === 'CompanyIsNotActive') {
			dispatch(showFixedLabel('No se ha podido reactivar, la empresa no está activa', 'error'));
		} else {
			dispatch(showFixedLabel('Ha surgido un error no se ha podido reactivar', 'error'));
		}
	}

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				{
					!rowData.isActive && isSuperAdmin &&
						<span
							className="material-icons-round btn-activate"
							data-testid={`btnActivateStandar_${rowData.index}`}
							role='navigation'
							aria-hidden={true}
							id={`btnActivateStandar_${rowData.index}`}
							onClick={() => handleActivateFromGrid(rowData.id)}
							
							type="button"
						>
							power_settings_new
						</span>
				}
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					role='navigation'
					aria-hidden={true}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() => handleRedirectFromGrid(rowData.id)}
					
					type="button"
				>
					mode_edit
				</span>
			</div>
		);
	};


	//filtrado
	useEffect(() => {
		if(!firstCall) {
			setCurrentPage(1)
			handleFilterSearch()
		}
	}, [
		filterDate,
		filterConcessionDate,
		companyComboSelected,
		budgetComboSelected,
		requestTypeComboSelected,
		questionnaireTypeComboSelected,
		checkHaveSecondaryCompanies,
		evaluationTypeComboSelected,
		contractsTypeComboSelected,
		applicantStatusTypeComboSelected,
		activeSelected,
		searchDebounce,
		auditorCompanyComboSelected,
		evaluatorSelected,
		improvementPlanStatusSelected,
		isPublicSelected
	])

	useEffect(() => {
		if(!firstCall) {
			handleFilterSearch()
		}
	}, [currentPage])

	const handleFilterSearch = () => {
		let filters = {
			filters: {
				fromDateRequest: filterDate.StartDate,
				toDateRequest: filterDate.EndDate,
				companyId: companyComboSelected ? companyComboSelected.id : null,
				budgetStatusTypeId: budgetComboSelected ? budgetComboSelected.id : null,
				fromDateConcession: filterConcessionDate.StartDate,
				toDateConcession: filterConcessionDate.EndDate,
				requestType: requestTypeComboSelected ? requestTypeComboSelected.id : null,
				questionnaireStatusTypeId: questionnaireTypeComboSelected ? questionnaireTypeComboSelected.id : null,
				returnSecondaryCompanies: checkHaveSecondaryCompanies ? checkHaveSecondaryCompanies : false,
				evaluationStatusTypeId: evaluationTypeComboSelected ? evaluationTypeComboSelected.id : null,
				contractsStatusTypeId: contractsTypeComboSelected ? contractsTypeComboSelected.id : null,
				renewalStatusTypeId: applicantStatusTypeComboSelected ? applicantStatusTypeComboSelected.id : null,
				showActiveRequestsOnly: activeSelected.value,
				companyName: searchDebounce ? searchDebounce : null,
				auditorCompanyId: auditorCompanyComboSelected ? auditorCompanyComboSelected.id : null,
				evaluatorUser: evaluatorSelected ? evaluatorSelected.id : null,
				improvementPlanStatus: improvementPlanStatusSelected ? improvementPlanStatusSelected.value : null,
				isPublic: isPublicSelected ? isPublicSelected.value : null
			}
		}
		
		dispatch(startRequest(postFilterRequests(filters, (currentPage - 1) * itemPerPage, itemPerPage, postFilterRequestsSuccess)))
	}

	const postFilterRequestsSuccess = (data) => {
		setRequests(data.list)
		setTotalCount(data.totalCount)
	}
	

	const resetFilters = () => {
		setBudgetComboSelected([])
		setCompanyComboSelected([])
		setRequestTypeComboSelected([])
		setQuestionnaireTypeComboSelected([])
		setEvaluationTypeComboSelected([])
		setContractsTypeComboSelected([])
		setApplicantStatusTypeComboSelected([])
		setActiveSelected(activeTypeOptions[0])
		setCheckHaveSecondaryCompanies(false)
		setSearch('')
		setRequests(allRequests)
		setFilterDate({});
		setFilterConcessionDate({})
		setCurrentPage(1)
		setEvaluatorSelected('')
		setAuditorCompanyComboSelected('')
		setImprovementStatusSelected('')
		setIsPublicSelected(null)
	}

	const dateTemplate = (rowData) => {
		return new Date(rowData.dateRequest).toLocaleDateString()
	}
	const dateConcessionTemplate = (rowData) => {
		if(rowData.concessionDate){
			return new Date(rowData.concessionDate).toLocaleDateString()
		}else{
			return false
		}
	}

	const budgetTemplate = (rowData) => {
		let iconColor = '#ABD268'
		switch (rowData.budgetStatus) {
			case 'Pendiente':
				iconColor = '#F8D750'
				break;
			case 'Aceptado':
				iconColor = '#ABD268'
				break;
			case 'Rechazado':
				iconColor = '#DC0513'
				break;
			default:
				break;
		}
		return(
			<div className='seal-template'>
				{
					rowData.requestType !== 1 &&
						<span className='material-icons-round' style={{color:`${iconColor}`}} aria-hidden={true}>request_page</span>
				}
				<span className={`${rowData.requestType !== 1 ? '' : 'text-silver'}`}>{`${rowData.requestType !== 1 ? '' : 'N/A'}`}</span>
			</div>
		)
	}
	const sealTemplate = (rowData) => {
		let iconColor = '#ABD268'
		switch (rowData.sealStatus) {
			case 'Pendiente':
				iconColor = '#F8D750'
				break;
			case 'Aceptado':
				iconColor = '#ABD268'
				break;
			case 'Rechazado':
				iconColor = '#DC0513'
				break;
			case 'Concedido':
				iconColor = 'rgb(171, 210, 104)'
				break;
			default:
				break;
		}
		return(
			<div className='seal-template'>
			{
				rowData.requestType !== 1 &&
					<span className='material-icons-round' style={{color:`${iconColor}`}} aria-hidden={true}>star</span>
			}
			<span className={`${rowData.requestType !== 1 ? '' : 'text-silver'}`}>{`${rowData.requestType !== 1 ? '' : 'N/A'}`}</span>
			</div>

		)
	}
	const questionnaireTemplate = (rowData) => {
		let stylesTemplate = {
			bgColor: '#ABD268',
			color: 'inherit'
		}
		switch (rowData.questionnaireStatus) {
			case 'Pendiente':
				stylesTemplate.bgColor = '#F8D750'
				break;
			case 'Completado':
				stylesTemplate.bgColor = '#ABD268'
				break;
			case 'Validado':
				stylesTemplate.bgColor = 'rgb(171, 210, 104)'
				break;
			case 'Rechazado':
				stylesTemplate.bgColor = '#DC0513'
				stylesTemplate.color = '#fff'
				break;
			default:
				break;
		}
		return(
			<div className='questionnaire-template'>
				{
					rowData.requestType !== 1 && rowData.isCompanyPrincipal
						? <span 
							style={{
								backgroundColor:`${stylesTemplate.bgColor}`,
								color:`${stylesTemplate.color}`
							}}
						>
							{`${rowData.questionnaireStatus}`}
						</span>
						: <span className='text-silver'>{'N/A'}</span>
				}
			</div>
		)
	}

	const evaluationTemplate = (rowData) => {
		let iconColor = '#ABD268'
		switch (rowData.evaluationStatus) {
			case 'Pendiente informe':
				iconColor = '#F8D750'
				break;
			case 'Pendiente de validar':
				iconColor = '#F8D750'
				break;
			case 'Completado':
				iconColor = '#ABD268'
				break;
			case 'Sin fecha de visita':
				iconColor = '#28ABB0'
				break;
			case 'Sin entidad':
				iconColor = '#DC0513'
				break;
			default:
				break;
		}
		return(
			rowData.isCompanyPrincipal
				? <div className='seal-template'>
					<span className='material-icons-round' style={{color:`${iconColor}`}} aria-hidden={true}>assignment</span>
					<span>{`${rowData.evaluationStatus}`}</span>
				</div>
				: <span className='text-silver'>{'N/A'}</span>
		)
	}

	const requestTemplate = (rowData) => {
		let iconColor = '#ABD268';
		switch (rowData.requestType) {
			case 0:
				iconColor = '#F8D750'
				break;
			case 1:
				iconColor = '#ABD268'
				break;
			case 2:
				iconColor = '#28ABB0'
				break;
			default:
				break;
		}
		return (
			<div className='type-template'>
				<span className='material-icons-round' style={{color:`${iconColor}`}} aria-hidden={true}>label</span>
				<span>{`${rowData.requestTypeValue === 'Request' ? 'Concesión' : t(rowData.requestTypeValue)}`}</span>
			</div>
		)
	}

	const plansTemplate = (rowData) => {
		let iconColor = '';
		let labelIcon = '';
		switch (rowData.improvementPlanStatusId) {
			case "d8f89a86-6716-45ff-8211-9a93572a554b": //'Pendiente'
				iconColor = '#F8D750';
				labelIcon = 'cancel';
				break;
            case "c06c4f1a-cb83-46bb-ad82-18c700875e61": // Completado
				iconColor = '#ABD268';
				labelIcon = 'check_circle';
				break;		
			default:
				break;
		}
		return (			
			rowData.isCompanyPrincipal
				? <div className='seal-template'>
					<span className='material-icons-round' style={{color:`${iconColor}`}} aria-hidden={true}>{labelIcon}</span>
					{/* <span>{t(rowData.improvementPlanStatus)}</span> */}
				</div>
				: <span className='text-silver'>{'N/A'}</span>
		)
	}

	const principalTemplate = (rowData) => {
		let iconColor = '';
		let labelIcon = '';
		switch (rowData.isCompanyPrincipal) {
			case false: //'Pendiente'
				iconColor = '#F8D750';
				labelIcon = 'A';
				break;
            case true: // Completado				
				iconColor = '#28ABB0';
				labelIcon = 'P';
				break;		
			default:
				break;
		}
		return (
			
				rowData.isCompanyPrincipal && rowData.hasSecondaryCompanies || !rowData.isCompanyPrincipal
					? <div className='seal-template'>
						<span className='icon-custom' style={{backgroundColor:`${iconColor}`}} aria-hidden={true}>{labelIcon}</span>
					</div>
					: null
		)
	}

	const colorRow = (rowData) => {
		if(rowData.data.isActive) {
			rowData.row.classList.add('is-active')
		} else {
			rowData.row.classList.add('is-no-active')
		}
	}

	return (
		<div className="companies">
			<HeaderPages 
				title={isCompany ? t('Requests') : t('Companies')}
				userLogedName={userLogedName}
			/>
			{
				!isCompany ? (
					<div className="filters-container row">
						<div className="col-lg-12 ">
							<div className='free-search-wrapper'>
								<Input
									inputName="txtSearch"
									inputPlaceholder={t('Search')}
									inputType="text"
									value={search}
									inputOutline={true}
									icon={'search'}
									iconLeft={true}
									isRequired={false}
									onHandleChange={(e) => setSearch(e.target.value)}
									// onHandleKeyUp={() => filterRequests()}
								/>

							</div>
						</div>
						<div className="col-lg-12 flex-container">
							{ !isAuditor &&
							<div className='fields-search-wrapper filter-dates'>
								<label className='filter-input-label'>{t('RequestDate')}</label>
								<div className='row-wrapper'>
									<div className='date-wrapper '>
										<DatePickerComponent
											showTodayButton={false}
											allowEdit={false}
											openOnFocus={true}
											isRequired={true}
											value={filterDate.StartDate ? filterDate.StartDate : null}
											onChange={(e) => {
												setFilterDate({
													...filterDate,
													//StartDate: parseDate(e.value)
													StartDate: e.value
												});
											}}
											placeholder="Desde"
										/> 
									</div>
									<div className='date-wrapper '>
										<DatePickerComponent
											showTodayButton={false}
											allowEdit={false}
											openOnFocus={true}
											isRequired={true}
											value={filterDate.EndDate ? filterDate.EndDate : null}
											onChange={(e) => {
												setFilterDate({
													...filterDate,
													//EndDate: parseDate(e.value)
													EndDate: e.value
												});
											}}
											placeholder="Hasta"
										/> 
									</div>
								</div>
							</div>
							}
							<div className='fields-search-wrapper'>
								<div className='form-input-group select-group'>
									<Combo 
										id="Entity"
										data-testid="Entity"
										defaultInputValue=""
										options={companiesOptions}
										isSearchable={true}
										isClearable={true}
										className="comboBox"
										handleChange={(e) => {
											setCompanyComboSelected(e)
											setCheckHaveSecondaryCompanies(false)
										}}
										inputValue={companyComboSelected}
										isMultiple={false}
										comboLabel={t('Company')}
										comboPlaceholder={t('Company')}
									/> 
								</div>
							</div>							
							{
								!isAuditor ? (
									<div className='fields-search-wrapper'>
										<div className='form-input-group select-group'>
											<Combo 
												id="BudgetType"
												data-testid="BudgetType"
												defaultInputValue=""
												options={budgetOptions}
												isSearchable={false}
												isClearable={true}
												className="comboBox"
												handleChange={(e) => setBudgetComboSelected(e)}
												inputValue={budgetComboSelected}
												isMultiple={false}
												comboLabel={t('Budget')}
												comboPlaceholder={t('Budget')}
											/> 
										</div>
									</div> 
								) : null
							}
							<div className='fields-search-wrapper'>
								<div className='form-input-group select-group'>
									<Combo 
										id="QuestionnaireType"
										data-testid="QuestionnaireType"
										defaultInputValue=""
										options={questionnaireTypeOptions}
										isSearchable={false}
										isClearable={true}
										className="comboBox"
										handleChange={(e) => setQuestionnaireTypeComboSelected(e)}
										inputValue={questionnaireTypeComboSelected}
										isMultiple={false}
										comboLabel={t('Questionnaire')}
										comboPlaceholder={t('Questionnaire')}
									/> 
								</div>
							</div>
							{
								isSuperAdmin ? (
									<>									
										<div className='fields-search-wrapper'>
											<div className='form-input-group select-group'>
												<Combo 
													id="EvaluationType"
													data-testid="EvaluationType"
													defaultInputValue=""
													options={evaluationTypeOptions}
													isSearchable={false}
													isClearable={true}
													className="comboBox"
													handleChange={(e) => setEvaluationTypeComboSelected(e)}
													inputValue={evaluationTypeComboSelected}
													isMultiple={false}
													comboLabel={t('Evaluation')}
													comboPlaceholder={t('Evaluation')}
												/> 
											</div>
										</div>
										<div className='fields-search-wrapper'>
											<div className='form-input-group select-group'>
												<Combo 
													id="ContractType"
													data-testid="ContractType"
													defaultInputValue=""
													options={contractsTypeOptions}
													isSearchable={false}
													isClearable={true}
													className="comboBox"
													handleChange={(e) => setContractsTypeComboSelected(e)}
													inputValue={contractsTypeComboSelected}
													isMultiple={false}
													comboLabel={t('Contract')}
													comboPlaceholder={t('Contract')}
												/> 
											</div>
										</div>
									</>

								) : (
									isAuditor && <div className='fields-search-wrapper'>
											<div className='form-input-group select-group'>
												<Combo 
													id="EvaluationType"
													data-testid="EvaluationType"
													defaultInputValue=""
													options={evaluationTypeOptions}
													isSearchable={false}
													isClearable={true}
													className="comboBox"
													handleChange={(e) => setEvaluationTypeComboSelected(e)}
													inputValue={evaluationTypeComboSelected}
													isMultiple={false}
													comboLabel={t('Evaluation')}
													comboPlaceholder={t('Evaluation')}
												/> 
											</div>
										</div>
								)
							}
							<div className='fields-search-wrapper'>
								<div className='form-input-group select-group'>
									<Combo 
										id="RequestType"
										data-testid="RequestType"
										defaultInputValue=""
										options={requestTypeOptions}
										isSearchable={false}
										isClearable={true}
										className="comboBox"
										handleChange={(e) => setRequestTypeComboSelected(e)}
										inputValue={requestTypeComboSelected}
										isMultiple={false}
										comboLabel={t('Type')}
										comboPlaceholder={t('Type')}
									/> 
								</div>
							</div>
							{
								requestTypeComboSelected && requestTypeComboSelected.id === 2 &&
									<div className='fields-search-wrapper'>
										<div className='form-input-group select-group'>
											<Combo 
												id="ApplicantStatusType"
												data-testid="ApplicantStatusType"
												defaultInputValue=""
												options={applicantStatusTypeOptions}
												isSearchable={false}
												isClearable={true}
												className="comboBox"
												handleChange={(e) => setApplicantStatusTypeComboSelected(e)}
												inputValue={applicantStatusTypeComboSelected}
												isMultiple={false}
												comboLabel={t('Status')}
												comboPlaceholder={t('Status')}
											/> 
										</div>
									</div>
							}							
							<div className='fields-search-wrapper filter-dates'>
								<label className='filter-input-label'>{t('ConcessionDate')}</label>
								<div className='row-wrapper'>
									<div className='date-wrapper '>
										<DatePickerComponent
											showTodayButton={false}
											allowEdit={false}
											openOnFocus={true}
											isRequired={true}
											value={filterConcessionDate.StartDate ? filterConcessionDate.StartDate : null}
											onChange={(e) => {
												setFilterConcessionDate({
													...filterConcessionDate,
													//StartDate: parseDate(e.value)
													StartDate: e.value
												});
											}}
											placeholder="Desde"
										/> 
									</div>
									<div className='date-wrapper '>
										<DatePickerComponent
											showTodayButton={false}
											allowEdit={false}
											openOnFocus={true}
											isRequired={true}
											value={filterConcessionDate.EndDate ? filterConcessionDate.EndDate : null}
											onChange={(e) => {
												setFilterConcessionDate({
													...filterConcessionDate,
													//EndDate: parseDate(e.value)
													EndDate: e.value
												});
											}}
											placeholder="Hasta"
										/> 
									</div>
								</div>
							</div>
							{
								isSuperAdmin ? (
									<div className='fields-search-wrapper'>
										<div className='form-input-group select-group'>
											<Combo 
												id="ActiveType"
												data-testid="ActiveType"
												defaultInputValue={activeTypeOptions[0]}
												options={activeTypeOptions}
												isSearchable={false}
												isClearable={true}
												className="comboBox"
												handleChange={(e) => setActiveSelected(e)}
												inputValue={activeSelected}
												isMultiple={false}
												comboLabel={t('Active')}
												comboPlaceholder={t('Active')}
											/> 
										</div>
									</div>
									
								) : null
							}
							{
								isSuperAdmin ? (
									<div className='fields-search-wrapper'>
										<div className='form-input-group select-group'>
											<Combo 
												id="AuditorCompany"
												data-testid="AuditorCompany"
												defaultInputValue={null}
												options={auditorCompanyOptions}
												isSearchable={true}
												isClearable={true}
												className="comboBox"
												handleChange={(e) => setAuditorCompanyComboSelected(e)}
												inputValue={auditorCompanyComboSelected}
												isMultiple={false}
												comboLabel={t('AuditorCompany')}
												comboPlaceholder={t('AuditorCompany')}
											/> 
										</div>
									</div>
									
								) : null
							}
							{
								isSuperAdmin ? (
									<div className='fields-search-wrapper'>
										<div className='form-input-group select-group'>
											<Combo 
												id="RequestEvaluator"
												data-testid="RequestEvaluator"
												defaultInputValue={null}
												options={evaluatorOptions}
												isSearchable={true}
												isClearable={true}
												className="comboBox"
												handleChange={(e) => setEvaluatorSelected(e)}
												inputValue={evaluatorSelected}
												isMultiple={false}
												comboLabel={t('RequestEvaluator')}
												comboPlaceholder={t('RequestEvaluator')}
											/> 
										</div>
									</div>
									
								) : null
							}
							{
								isSuperAdmin ? (
									<div className='fields-search-wrapper'>
										<div className='form-input-group select-group'>
											<Combo 
												id="improvementPlanStatus"
												data-testid="improvementPlanStatus"
												defaultInputValue={null}
												options={improvementPlanStatusOptions}
												isSearchable={false}
												isClearable={true}
												className="comboBox"
												handleChange={(e) => setImprovementStatusSelected(e)}
												inputValue={improvementPlanStatusSelected}
												isMultiple={false}
												comboLabel={t('ImprovementPlanLong')}
												comboPlaceholder={t('ImprovementPlanLong')}
											/> 
										</div>
									</div>
									
								) : null
							}
							{
								isSuperAdmin ? (
									<div className='fields-search-wrapper'>
										<div className='form-input-group select-group'>
											<Combo 
												id="isPublic"
												data-testid="isPublic"
												defaultInputValue={null}
												options={isPublic}
												isSearchable={false}
												isClearable={true}
												className="comboBox"
												handleChange={(e) => setIsPublicSelected(e)}
												inputValue={isPublicSelected}
												isMultiple={false}
												comboLabel={t('Public/Private')}
												comboPlaceholder={t('Public/Private')}
											/> 
										</div>
									</div>
									
								) : null
							}
							<div className='fields-search-wrapper'>
								<div className='form-input-group btn-group'>
									<Button
										btnText={t('ResetFilters')}
										btnType="button"
										btnClass="btn-base_normal "
										id="resetFilters"
										onHandleBtnClick={() => resetFilters()}
									/>
								</div>
							</div>							
						</div>
						<div className="col-lg-12">														
							{
								companyComboSelected && companyComboSelected.isCompanyPrincipal &&
									<div className="fields-search">		
										<Checkbox
											inputID={'checkHaveSecondaryCompany'}
											dataTestID={'checkHaveSecondaryCompany'}
											inputName={'HaveSecondaryCompany'}
											inputLabel={t('HaveSecondaryCompany')}
											inputChecked={checkHaveSecondaryCompanies}
											onHandleChange={(e) => {
												setCheckHaveSecondaryCompanies(!checkHaveSecondaryCompanies)
											}}
											withLink={false}
										/>
									</div>
							}
						</div>
					</div>

				) : null
			}
			
			<div className='request-grid row'>
				<div className="col-md-12">
					{/* <ResponsiveGridWrapper> */}
					<div className='grid-wrapper' style={{ flex: "1", width: "100%", height: "100%" }}>
						<GridComponent
							className="gridTable"
							dataSource={requests}
							width="100%"
							toolbar={false}
							ref={grid}
							//allowExcelExport={true}
							allowPaging={false}
							enableAdaptiveUI={true}
							allowSorting={true}
							rowRenderingMode={'Vertical'}
							allowResizing={true}
							locale="es"
							rowDataBound={colorRow}
							sortSettings={
								{
									columns:[{
										field: 'dateRequest',
										direction: 'Descending'
									}]
								}
							}
						>
							<ColumnsDirective>
								<ColumnDirective field="id" width="0" visible={false} />
								<ColumnDirective 
									field="dateRequest" 
									headerText={t('RequestDate')} 
									template={dateTemplate} 
									textAlign="Left"
									width="120" 
									visible={false}
								/>
								{
									!isCompany ? (										
										<ColumnDirective 
											field="principalStatus" 
											headerText=""
											template={principalTemplate} 
											textAlign="Left" 
											width="30"
										/>

									) : null
								}
								<ColumnDirective 
									field="companySocialName" 
									headerText={t('SocialName')} 
									textAlign="Left"
									// width='250'
								/>	
								{
									isSuperAdmin ? (										
										<ColumnDirective 
											field="budgetStatus" 
											headerText={t('Budget')} 
											template={budgetTemplate} 
											textAlign="Left" 
											width="75"
										/>

									) : null
								}
								<ColumnDirective 
									field="questionnaireStatus" 
									headerText={t('Questionnaire')} 
									template={questionnaireTemplate} 
									textAlign="Left" 
									width="100" 
								/>
								<ColumnDirective 
									field="evaluationStatus"
									headerText={t('Evaluation')} 
									template={evaluationTemplate} 
									textAlign="Left" 
									width="100" 
								/>{
									!isAuditor  ? (
										<ColumnDirective 
											field="sealStatus" 
											headerText={t('Seal')} 
											template={sealTemplate} 
											textAlign="Left" 
											width="30" 
										/>
									) : null
								}
								<ColumnDirective 
									field="concessionDate" 
									headerText={t('CSRDate')} 
									template={dateConcessionTemplate} 
									textAlign="Left" 
									width="100" 
								/>
								{/* <ColumnDirective 
									field="renewal" 
									headerText={t('Renewal')} 
									displayAsCheckBox="true" 
									textAlign="Center" 
									width="120"
								/> */}	
								<ColumnDirective 
									field="requestTypeValue" 
									headerText={t('Type')}
									template={requestTemplate}
									textAlign="Left" 
									width="100"
								/>							
								<ColumnDirective 
									field="improvementPlanStatus" 
									headerText={t('ImprovementPlansShort')}
									template={plansTemplate}
									textAlign="Left" 
									width="40"
								/>							
								<ColumnDirective
									field="actions"
									headerText=''
									template={actionsTemplate}
									textAlign="Right"
									width="50"
								/>
							</ColumnsDirective>
							<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
						</GridComponent>
						
						<Paginator
							totalItems={totalCount}
							page={currentPage}
							defaultPageSize={itemPerPage}
							onPageChange={(page) => setCurrentPage(page)}
							blockNumber={3}
						/>

						{
							showExportExcelBtn ? (
								<div className="export-excel-wrapper">
									<div className="col-lg-12">
										<Button
											btnText={t('ExportExcel')}
											btnType="button"
											btnClass="btn-base_normal export-excel-button"
											onHandleBtnClick={() => {
												grid.current.excelExport();
											}}
										/>
									</div>
								</div>

							) : null
						}
					{/* </ResponsiveGridWrapper> */}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Companies;
