import React, {useState, useEffect, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Logo from '../../../assets/logos/me_azul2.png';
import { GetSessionStorage } from '../../../common/utils/storageManager';
import './SelfAssessment.scss';
import Radio from '../../../components/FormComponents/RadioButton/Radio';
import Button from '../../../components/FormComponents/Button';
import Textarea from '../../../components/FormComponents/Textarea';
import { 
    getAutoEvaluationDataParams, 
    getUserLoggedParams,
    postSaveAutoevaluationData,
    postSendAutoevaluationData
} from './SelfAssessmentCalls/SelfAssessmentCalls';
import { showFixedLabel } from '../../../actions/fixedLabel';
import { ROUTE } from '../../../common/constants/routes';
import { startRequest } from '@backoffice/actions/apiCall';
import { openModal, closeModal } from '../../../actions/popup';
import SelfAssessmentModal from './SelfAssessmentModal';
import SelfAssessmentDescriptionModal from './SelfAssessmentDescriptionModal';
import FixedLabel from '../../../shared/FixedLabel/FixedLabel';

const SelfAssessment = (props) => {
    const history = useHistory()
    const { t } = useTranslation();
	const dispatch = useDispatch();

    const [requestId, setRequestId] = useState('')
    const [socialName, setSocialName] = useState('')
    const [userLogedName, setUserLogedName] = useState('')
    const [progressWidthBar, setProgressWidthBar] = useState(0)
    const [asideTop, setAsideTop] = useState(190)
    const [asideHeight, setAsideHeight] = useState(188)
    const [rol, setRol] = useState('')

    const [editMode, setEditMode] = useState(false);

    const [selfAssessmentData, setSelfAssessmentData] = useState({})


    useEffect(() => {
        if(history.location.state){
            setRequestId(history.location.state.id)
            setSocialName(history.location.state.socialName)
        }else{
            //get id from params
            const params = window.location.pathname
            setRequestId(params.split('/')[2])
        }
        //#1
        getUserLoged();
    }, [])

    const getUserLoged = () =>{
        dispatch(startRequest(getUserLoggedParams(getUserLoggedParamsSuccess)))
	}

    const getUserLoggedParamsSuccess = (data) => {
        setRol(data.userRoles[0].name)
        //setRol('Auditor')
        setUserLogedName(data.name)
    }

    useEffect(() => {
        if(requestId !== '') {
            //#2
            getSelfAssessment()
        }
    }, [requestId])

    const [loadOk, setLoadOk] = useState(false)

    const getSelfAssessment = () => {
        if(requestId !== ''){
            dispatch(startRequest(getAutoEvaluationDataParams(requestId, getAutoEvaluationDataParamsSuccess)))
        } else {
            dispatch(showFixedLabel(t('GenericSelfAssessmentError'), 'error'))
        }
    }

    const getAutoEvaluationDataParamsSuccess = (data) => {
        setSelfAssessmentData(data)
        setLoadOk(true)
    }

    useEffect(() => {
        getProgresBarPercent()
    }, [selfAssessmentData.statistics])

    //UI Effects
    useEffect(() => {
        if(loadOk) {
            const sections = document.querySelectorAll(".selfassessment-content .anchor-container");
            const navLi = document.querySelectorAll(".menu-list.ul .menu-list__item");
            const aside = document.querySelector('aside')
            window.addEventListener("scroll", () => {
                let current = "";
                setAsideTop(190)
                let control = 0;
                //TODO Refactor foreach
                sections.forEach((section, index) => {
                    const sectionTop = section.offsetTop;
                    const sectionHeight = section.clientHeight;
                    
                    if (index === 0 && control === 0) {
                        //introduction
                        if (parseInt(Math.ceil(window.scrollY))  <= sectionHeight + sectionTop){
                            current = section.getAttribute("id");
                            if(aside.querySelector('.menu-list__item.active')){
                                aside.querySelector('.menu-list__item.active').scrollIntoView();
                            }
                            control = 1;
                        }
                    } else if(control === 0) {
                        if (parseInt(Math.ceil(window.scrollY + 2)) >= sectionTop && parseInt(Math.ceil(window.scrollY + 2)) < sectionTop + sectionHeight){
                            current = section.getAttribute("id");
                            if(aside.querySelector('.menu-list__item.active')){
                                aside.querySelector('.menu-list__item.active').scrollIntoView();
                            }
                            control = 1;
                        }
                    }
                });
    
                if(window.scrollY > 80){
                    setAsideTop(15)
                    setAsideHeight(72)
                }
               
                navLi.forEach((li) => {
                    li.classList.remove("active");
                    if (li.classList.contains(current)) {
                        li.classList.add("active");
                    }
                });
              });
        }
    }, [loadOk])

    const titleDescription = (
        e, 
        question,
        indexSecondLevel,
        indexThirdLevel,
        indexFourthLevel,
        indexFiveLevel
    ) => {
        const optionValues = {
            id: 'selfAssesmentDescriptionModal',
            dataTestId: 'selfAssesmentDescriptionModal',
            cssClass: 'modal-header__transparent',
            title: ' '
        };
        // console.log(
        //     //e, 
        //     question,
        //     indexSecondLevel,
        //     indexThirdLevel,
        //     indexFourthLevel,
        //     indexFiveLevel
        // );
        let obj = {...selfAssessmentData}
        const options = {
            ...optionValues,
            body: (
                <SelfAssessmentDescriptionModal 
                    e={e}
                    selfAssessment={obj}
                    question={question}
                    indexSecondLevel={indexSecondLevel}
                    indexThirdLevel={indexThirdLevel}
                    indexFourthLevel={indexFourthLevel}
                    indexFiveLevel={indexFiveLevel}
                    description={question.questionDescription}
                    handleSubmit={() => handlePostSelfAssessment('descriptionModalSave')}
                    userRoll={rol}
                    isCompleted={selfAssessmentData.isCompletedForAuditor}
                />
            )
        };
        dispatch(openModal(options));
    }

    const handleTextChange = (
        e,
        indexSecondLevel,
        indexThirdLevel,
        indexFourthLevel,
        indexFiveLevel
    ) => {
        const textValue = e.target.value;
        let obj = {...selfAssessmentData}
        obj.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].otherAnswer = textValue
        setSelfAssessmentData(obj)
    }

    const handleCheckChange = (
        e,
        indexSecondLevel,
        indexThirdLevel,
        indexFourthLevel,
        indexFiveLevel,
        indexAnswer
    ) => {
        //let stateCopy = Object.assign({}, selfAssessmentData)

        let obj = {...selfAssessmentData}

         obj.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].answers.forEach((el, index) => {
             //console.log(el, index, indexAnswer);
             if (index === indexAnswer) {
                obj.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].answers[index].companyAnswer = true
             }else{
                obj.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].answers[index].companyAnswer = false
             }
         })

        setSelfAssessmentData(obj)
    }

     const handleCheckAuditorChange = (
        e,
        indexSecondLevel,
        indexThirdLevel,
        indexFourthLevel,
        indexFiveLevel,
        indexAnswer
     ) => {
        let obj = {...selfAssessmentData}
        obj.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].answers.forEach((el, index) => {
            //console.log(el, index, indexAnswer);
            if (index === indexAnswer) {
            obj.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].answers[index].auditorAnswer = true
            }else{
            obj.contents[indexSecondLevel].subasidesContent[indexThirdLevel].subasidesDetail[indexFourthLevel].questions[indexFiveLevel].answers[index].auditorAnswer = false
            }
        })

        setSelfAssessmentData(obj)
    }

    const handleActionSelfAssessment = (e, action) => {
        const optionValues = {
            id: 'selfAssesmentModal',
            dataTestId: 'selfAssesmentModal',
            cssClass: 'modal-header__transparent',
            title: t(action)
        };

        const options = {
            ...optionValues,
            body: (
                <SelfAssessmentModal
                    requestId={requestId}
					action={action}
                    socialName={socialName}
					handleSubmit={handlePostSelfAssessment}
                    userRol={rol}
                />
            )
        };
        dispatch(openModal(options));
    }

    const handlePostSelfAssessment = (action) => {
        //console.log(action, selfAssessmentData);
        if(action === 'descriptionModalSave'){
            dispatch(showFixedLabel('Descripción guardada con éxito', 'success'))
            //then call get to redraw
            getSelfAssessment()
        }
        if(action === 'save'){
            //call endpoint to save data
            dispatch(startRequest(postSaveAutoevaluationData(selfAssessmentData, postSaveAutoevaluationDataSuccess, postSaveAutoevaluationDataError)))
        }
        if(action === 'send'){
            //call endpoint to send data
            dispatch(startRequest(postSendAutoevaluationData(selfAssessmentData, postSendAutoevaluationDataSuccess, postSendAutoevaluationDataError)))
        }
        if(action === 'edit'){
            setEditMode(!editMode)
        }
    }

    const postSaveAutoevaluationDataSuccess = (data) => {
        dispatch(showFixedLabel('Formulario guardado con éxito', 'success'))
        //then call get to redraw
        getSelfAssessment()    
    }
    const postSaveAutoevaluationDataError = (error) => {
        dispatch(showFixedLabel(t(error.errors[0].errorMessageKey), 'error'))
        getSelfAssessment()
    }

    const postSendAutoevaluationDataSuccess = (data) => {
        dispatch(showFixedLabel('Formulario enviado con éxito', 'success')) 
        //then call get to redraw
        getSelfAssessment()
        //redirect to detailId
        setTimeout(() => {
            history.push({
                pathname: rol !== 'Company' ? ROUTE.REQUESTDETAIL.replace(':id', requestId) : ROUTE.SOLICITUDESID.replace(':id', requestId),
                state: {
                    requestId: requestId
                }
            })
        }, 1000);
    }
    const postSendAutoevaluationDataError = (error) => {
        dispatch(showFixedLabel(t(error.errors[0].errorMessageKey), 'error'))
        getSelfAssessment()
    }

    const getProgresBarPercent = () => {
        if(selfAssessmentData) {
            const stadistics = {...selfAssessmentData.statistics}
            let progressPercent = ''
            if(rol === 'Auditor') {
                progressPercent = (stadistics.totalQuestionsAnsweredByAuditor / stadistics.totalQuestionsByAuditor) * 100;
            }else{
                progressPercent = (stadistics.totalQuestionsAnsweredByCompany / stadistics.totalQuestionsByCompany ) * 100;
            }
            progressPercent = Math.ceil(progressPercent).toString()
            if(!isNaN(progressPercent)) setProgressWidthBar(progressPercent)
        }
    }
    
    return (
        <div className='selfassessment'>
            <FixedLabel parentType="general" />
            <div
                className={`top-progress-bar fixedbar`}
                style={{
                    width: `${progressWidthBar}%`
                }}
            >
            </div>
            <form className='selfassessment-form'>
                <div className='selfassessment__heading'>
                    <div className='heading-logo-container '>
                        <h1 className='logo-wrapper'>
                            <img className='logo-img' src={Logo} alt="logo madrid excelente" />
                        </h1>
                        <span className='sr-only' aria-labelledby='returnButton'>Volver atrás</span>
                        <button className='return-wrapper'
                            id='returnButton'
                            role='navigation'
                            onClick={() => {
                                history.push({
                                    pathname: rol !== 'Company' ? ROUTE.REQUESTDETAIL.replace(':id', requestId) : ROUTE.SOLICITUDESID.replace(':id', requestId),
                                    state: {
                                        requestId: requestId
                                    }
                                })
                            }}
                        >
                            <span className='material-icons' aria-hidden={true}>arrow_back</span>
                            <span className='return-link'>
                                {t('GoBack')}
                            </span>
                        </button>                  
                    </div>
                    <div className='user-container'>
                        <div className='help-wrapper'>
                            <div className='return-wrapper' onClick={() => window.print()} role='button'>
                                <span className='material-icons' aria-hidden={true}>print</span> 
                                <span className='return-link'>
                                    Imprimir pantalla
                                </span>
                            </div>
                        </div>
                        <div className='help-wrapper' >
                            <a className='return-wrapper' href='https://www.madridexcelente.com/' role='button'>
                                <span className='material-icons' aria-hidden={true}>arrow_back</span> 
                                <span className='return-link'>
                                    Volver a la web de Madrid Excelente
                                </span>
                            </a>                            
                        </div> 
                        <div className='user-wrapper'>
                            <span className='user-text'>{userLogedName}</span>
                        </div>
                    </div>
                </div>
                <div className='flex-container'>
                    <aside className='selfassessment-aside'
                        style={{
                            top: `${asideTop}px`,
                            height: `calc(100vh - ${asideHeight}px)`
                        }}
                    >
                        <div className='aside-content'>
                            <div className='menu-wrapper'>
                                <div className='menu-list ul'>
                                    <div className='menu-list__item introductionAnchor active'>
                                        <div className='item-wrapper'>
                                            <a href='#introductionAnchor' className='item-wrapper__title' aria-labelledby='introductionTitle'>Introducción</a>
                                        </div>
                                    </div>

                                        {
                                            selfAssessmentData.asides ?  selfAssessmentData.asides.map((itemAside, indexSecondLevel) => (
                                                    <div key={indexSecondLevel} className={`menu-list__item ${itemAside.titleAnchorId}`}>
                                                        <div className='item-wrapper'>
                                                            <a href={`#${itemAside.titleAnchorId}`} className='item-wrapper__title' aria-labelledby={itemAside.titleAsideId}>{itemAside.title}</a>
                                                            {
                                                                itemAside.subasides.map((subasideItem, indexThirdLevel) => (
                                                                    <div key={indexThirdLevel} className='item-wrapper__content'>
                                                                        <a href={`#${subasideItem.subAnchorId}`} className='sub-title'>{subasideItem.subtitle}</a>
                                                                        <div className='points-container'>
                                                                            {
                                                                                rol === 'Company' ? null : (
                                                                                    <div className='points-rate'>
                                                                                        {subasideItem.pointsRate ? Math.round(parseFloat(subasideItem.pointsRate)) : '0'}
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            {
                                                                                !subasideItem.allQuestionsAnsweredByCompany ? (
                                                                                    <div className='answer-rate'>{subasideItem.totalAnswered}</div>
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                )

                                            ) : null
                                        }

                                </div>
                            </div>
                        </div>
                    </aside>
                    <section className='selfassessment-content'>
                        <div className='row-container'>
                            <div id="introductionAnchor" className='title-wrapper anchor-container'>
                                <h2 id='introductionTitle' autoFocus={true} className="title">{`${t('Cuestionario de autoevaluación')} ${selfAssessmentData.questionnaireVersion ? `(${selfAssessmentData.isPublic !== null && selfAssessmentData.isPublic ? t("PublicSector") : t("PrivateSector") } V.${selfAssessmentData.questionnaireVersion})` : '' }`}</h2>
                                <div className='description-wrapper '>
                                    <p>El cuestionario de autoevaluación supone una revisión de tu organización en todas las áreas de la gestión. Es un ejercicio de reflexión interna que, posteriormente, podrás contrastar con un evaluador externo. </p>
                                    <p>A continuación, te facilitamos algunas indicaciones que te ayudarán a completarlo:</p>
                                    <p>El cuestionario de autoevaluación está compuesto por los cuatro fundamentos que definen el modelo de referencia Madrid Excelente. Cada uno de estos fundamentos se divide en una serie de áreas de evaluación, hasta un total de diez, que a su vez tiene asociadas unas cuestiones. En total, se proponen 124 cuestiones. </p>
                                    <p> El tiempo necesario para cumplimentar el cuestionario se estima en aproximadamente dos horas, aunque dependerá de la complejidad y características de tu organización.</p>
                                    <p>Puedes cumplimentar el cuestionario en cuantas sesiones consideres necesarias, pero recuerda utilizar el botón de Guardar, en el menú inferior, para no perder los avances antes de salir de cada sesión.</p>
                                    <p>No es obligatorio seguir un orden concreto en el que cumplimentar el cuestionario, puedes utilizar el menú de navegación izquierdo para seleccionar un fundamento o área y así completarlo en el orden que prefieras. </p>
                                    <p>El menú de navegación izquierdo te indicará en todo momento información útil: en qué nivel del cuestionario te encuentras, qué áreas has completado o cuántas cuestiones te faltan por completar en cada área.</p>
                                    <p>Todas las cuestiones constan de cinco respuestas. Es obligatorio seleccionar una de ellas en cada caso. La selección de múltiples respuestas no está permitida. </p>
                                    <p>Además, cada área tiene un apartado de texto libre (con una extensión limitada a 500 caracteres), denominado Otras iniciativas, en el que puedes explicar brevemente otras actividades que tu organización realiza en relación con esa área y que no se hayan contemplado en el cuestionario. La cumplimentación de este apartado no es obligatoria.</p>
                                    <p>Cuando hayas cumplimentado el cuestionario de autoevaluación, pulsa el botón “Enviar” en el menú inferior, para que Madrid Excelente lo revise y confirme su idoneidad antes de pasar a la fase de evaluación externa. La opción de envío estará disponible únicamente cuando se haya completado todo el cuestionario (con la excepción de los apartados de texto libre).</p>
                                    <p>Si necesitas cualquier aclaración, puedes acceder a la ayuda que ofrece la plataforma mediante el icono flotante que aparece en todo momento en pantalla o mediante las opciones de contacto con Madrid Excelente.</p>
                                </div>
                            </div>
                            <div className='selfassessment-wrapper'>
                                <div className='1 selfassessment-wrapper__item'>
                                    {
                                        selfAssessmentData.contents ? selfAssessmentData.contents.map((itemContent, indexSecondLevel) => (
                                            <div id={itemContent.titleAsideAnchorId} key={indexSecondLevel} className='2 questions-wrapper anchor-container'>
                                                <div className='block-item'>
                                                    <div className='title-wrapper'>
                                                        <h3 id={itemContent.titleAsideId} className="title-3">{itemContent.titleAside}</h3>
                                                    </div>
                                                    {
                                                        itemContent.subasidesContent.map((questions, indexThirdLevel) => (
                                                            <div className='3' key={indexThirdLevel}>
                                                                <div className='area-title-wrapper'>
                                                                    <h4 id={questions.subtitleAnchorId} className="title-4">
                                                                        {questions.subtitle} 
                                                                        {
                                                                            rol !== 'Company' ? (
                                                                                <span className='company-points'>
                                                                                    {questions.totalPointsByCompany ? Math.round(parseFloat(questions.totalPointsByCompany)) : '0'}
                                                                                </span>
                                                                            ) : null
                                                                        }
                                                                    </h4>
                                                                </div>
                                                                <div className='content-wrapper'>
                                                                    {
                                                                        questions.subasidesDetail.map((subasideDetail, indexFourthLevel) => (
                                                                            <div key={indexFourthLevel} className="4">
                                                                                <div className='content-title'>
                                                                                    <h5 className='title-5'>{subasideDetail.title}</h5>
                                                                                </div>
                                                                                <div className='questions-wrapper'>
                                                                                     <div className='radios-wrapper'>
                                                                                        {
                                                                                            subasideDetail.questions.map((question, indexFiveLevel) => (
                                                                                                <fieldset className={`5 questions ${question.answerType ? 'area-question' : ''}`} key={indexFiveLevel}>
                                                                                                    <div className='questions-heading'>
                                                                                                        <div className='content-title'>
                                                                                                            <label 
                                                                                                                role='heading' 
                                                                                                                aria-level='6' 
                                                                                                                className='title-6'
                                                                                                                dangerouslySetInnerHTML={{ __html: question.questionTitle}}
                                                                                                            />
                                                                                                                
                                                                                                            {/* <label role='heading' aria-level='6' className='title-6'>
                                                                                                                {question.questionTitle}
                                                                                                            </label> */}

                                                                                                            {
                                                                                                                rol === 'Auditor' ? (
                                                                                                                    <span role='button' onClick={(e) => titleDescription(
                                                                                                                        e, 
                                                                                                                        question,
                                                                                                                        indexSecondLevel,
                                                                                                                        indexThirdLevel,
                                                                                                                        indexFourthLevel,
                                                                                                                        indexFiveLevel
                                                                                                                    )} className="material-icons-outlined auditor-icon">
                                                                                                                        control_point
                                                                                                                    </span>
                                                                                                                ) : <span aria-hidden></span>
                                                                                                            }
                                                                                                        </div>
                                                                                                        {
                                                                                                            rol === "Auditor" ? (
                                                                                                                <p className='auditor-title'>{t('Auditor')}</p>
                                                                                                            ) : null
                                                                                                        }
                                                                                                     </div>
                                                                                                    {
                                                                                                        question.answerType === 0 ? question.answers.map((answer, indexAnswer) => (
                                                                                                            <div key={indexAnswer} className='6 radio-container'>
                                                                                                                <div className='company-column'>
                                                                                                                    {
                                                                                                                        rol !== "Company" ? (
                                                                                                                            <div className={`points ${!answer.companyAnswer ? 'hide' : ''}`}>
                                                                                                                                {answer.companyAnswer ? answer.rate : ''}
                                                                                                                            </div>

                                                                                                                        ) : (
                                                                                                                            <div className={`points hide`}></div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {
                                                                                                                        question.answerType === 0 ? (
                                                                                                                            <Radio
                                                                                                                                id={answer.id}
                                                                                                                                label={answer.title}
                                                                                                                                name={question.titleName}
                                                                                                                                value={answer.title}
                                                                                                                                isDisabled={rol !== 'Company' || selfAssessmentData.isCompletedForCompany ? true : false}
                                                                                                                                handleChecked={answer.companyAnswer === true ? true : false}
                                                                                                                                handleChange={(e) => handleCheckChange(
                                                                                                                                    e,
                                                                                                                                    indexSecondLevel,
                                                                                                                                    indexThirdLevel,
                                                                                                                                    indexFourthLevel,
                                                                                                                                    indexFiveLevel,
                                                                                                                                    indexAnswer
                                                                                                                                )}
                                                                                                                            />

                                                                                                                        ) : null
                                                                                                                    }

                                                                                                                </div>
                                                                                                                {
                                                                                                                    rol !== 'Company' ? (
                                                                                                                        <div className='auditor-column'>
                                                                                                                            <Radio
                                                                                                                                id={answer.id + 'Auditor'}
                                                                                                                                label={' '}
                                                                                                                                name={question.titleName + 'Auditor'}
                                                                                                                                value={true}
                                                                                                                                isDisabled={rol === 'Admin' || (selfAssessmentData.isCompletedForAuditor ? true : false ) && !editMode}
                                                                                                                                classList={'hideLabel'}
                                                                                                                                handleChecked={answer.auditorAnswer === true  ? true : false}
                                                                                                                                handleChange={(e) => handleCheckAuditorChange(
                                                                                                                                    e,
                                                                                                                                    indexSecondLevel,
                                                                                                                                    indexThirdLevel,
                                                                                                                                    indexFourthLevel,
                                                                                                                                    indexFiveLevel,
                                                                                                                                    indexAnswer
                                                                                                                                )}
                                                                                                                                //dataId={questions.subtitleAnchorId}
                                                                                                                            />
                                                                                                                            <div className={`points ${!answer.auditorAnswer ? 'hide' : ''}`}>
                                                                                                                                {answer.auditorAnswer ? answer.rate : ''}
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    ) : null
                                                                                                                }
                                                                                                            </div>

                                                                                                        )) : (
                                                                                                            <div className='form-input-group input-group'>
                                                                                                                <div className="form-wrapper">
                                                                                                                    <Textarea
                                                                                                                        inputLabel={question.questionTitle}
                                                                                                                        //id="notAllOrganizationDescription"
                                                                                                                        className="textarea-description"
                                                                                                                        isDisabled={rol !== 'Company' || selfAssessmentData.isCompletedForCompany ? true : false}
                                                                                                                        onHandleChange={(e) => handleTextChange(
                                                                                                                            e,
                                                                                                                            indexSecondLevel,
                                                                                                                            indexThirdLevel,
                                                                                                                            indexFourthLevel,
                                                                                                                            indexFiveLevel
                                                                                                                        )}
                                                                                                                        inputValue={question.otherAnswer}
                                                                                                                        max={500}
                                                                                                                    />
                                                                                                                </div>
                                                                                                                <div className='radio-container'>

                                                                                                               
                                                                                                                {
                                                                                                                    rol !== 'Company' ? question.answers.map((answer, indexAnswer) => (
                                                                                                                        <div key={indexAnswer} className='auditor-column'>
                                                                                                                            <Radio
                                                                                                                                id={answer.id + 'Auditor'}
                                                                                                                                label={' '}
                                                                                                                                name={question.titleName + 'Auditor'}
                                                                                                                                value={true}
                                                                                                                                isDisabled={rol === 'Admin' || (selfAssessmentData.isCompletedForAuditor ? true : false ) && !editMode}
                                                                                                                                classList={'hideLabel'}
                                                                                                                                handleChecked={answer.auditorAnswer  ? true : false}
                                                                                                                                handleChange={(e) => handleCheckAuditorChange(
                                                                                                                                    e,
                                                                                                                                    indexSecondLevel,
                                                                                                                                    indexThirdLevel,
                                                                                                                                    indexFourthLevel,
                                                                                                                                    indexFiveLevel,
                                                                                                                                    indexAnswer
                                                                                                                                )}
                                                                                                                            />
                                                                                                                            <div className={`points ${!answer.auditorAnswer ? 'hide' : ''}`}>
                                                                                                                                {answer.auditorAnswer ? answer.rate : ''}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )) : null
                                                                                                                }
                                                                                                            </div> 
                                                                                                        </div>

                                                                                                        )
                                                                                                    }

                                                                                                </fieldset>
                                                                                            ))
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        )) : null
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            rol === 'Company' ? (
                                <div className='btn-wrapper'>
                                    <div className='save-wrapper'>
                                        <Button
                                            btnText={t('Save')}
                                            btnType="button"
                                            btnClass="btn-base_normal "
                                            dataTestID="SaveSelfAssesmentBtn"
                                            id="SaveSelfAssesmentBtn"
                                            onHandleBtnClick={(e) => handleActionSelfAssessment(e, 'SaveQuestionnaire')}
                                            isDisabled={selfAssessmentData.isCompletedForCompany}
                                        />
                                    </div>
                                    <div className='actions-wrapper'>
                                        <Button
                                            btnText={t('Cancel')}
                                            btnType="button"
                                            btnClass="btn-base_outline"
                                            dataTestID="SaveCancelAssesmentBtn"
                                            id="SaveCancelAssesmentBtn"
                                            onHandleBtnClick={(e) => handleActionSelfAssessment(e, 'CancelQuestionnaire')}
                                            isDisabled={selfAssessmentData.isCompletedForCompany}
                                        />
                                        <Button
                                            btnText={t('Send')}
                                            btnType="button"
                                            btnClass="btn-base_normal "
                                            dataTestID="SaveSendAssesmentBtn"
                                            id="SaveSendAssesmentBtn"
                                            onHandleBtnClick={(e) => handleActionSelfAssessment(e, 'SendQuestionnaire')}
                                            isDisabled={selfAssessmentData.isCompletedForCompany}
                                        />
                                    </div>
                                </div>

                            ) : null
                        }
                        {
                            rol === 'Auditor' ? (
                                <div className='btn-wrapper'>
                                    <div className='save-wrapper'>
                                        <Button
                                            btnText={t('Save')}
                                            btnType="button"
                                            icon={'save'}
                                            btnClass="btn-base_normal "
                                            dataTestID="SaveSelfAssesmentBtn"
                                            id="SaveSelfAssesmentBtn"
                                            onHandleBtnClick={(e) => handleActionSelfAssessment(e, 'SaveQuestionnaire')}
                                            isDisabled={(selfAssessmentData.isCompletedForAuditor ? true : false ) && !editMode}
                                        />
                                    </div>
                                    <div className='actions-wrapper'>
                                        <Button 
                                            btnText={t("editMode")}
                                            btnType="button"
                                            icon={'edit'}
                                            btnClass="btn-base_outline"
                                            dataTestID="EditAssesmentBtn"
                                            id="EditAssesmentBtn"
                                            onHandleBtnClick={(e) => handleActionSelfAssessment(e, 'EditQuestionnaire')}
                                        />
                                        <Button
                                            btnText={t('Cancel')}
                                            btnType="button"
                                            btnClass="btn-base_outline"
                                            dataTestID="SaveCancelAssesmentBtn"
                                            id="SaveCancelAssesmentBtn"
                                            onHandleBtnClick={(e) => handleActionSelfAssessment(e, 'CancelQuestionnaire')}
                                            isDisabled={selfAssessmentData.isCompletedForAuditor}
                                        />
                                        <Button
                                            btnText={t('Send')}
                                            btnType="button"
                                            icon={'send'}
                                            btnClass="btn-base_normal "
                                            dataTestID="SaveSendAssesmentBtn"
                                            id="SaveSendAssesmentBtn"
                                            onHandleBtnClick={(e) => handleActionSelfAssessment(e, 'SendQuestionnaire')}
                                            isDisabled={(selfAssessmentData.isCompletedForAuditor ? true : false ) && !editMode}
                                        />
                                    </div>
                                </div>

                            ) : null
                        }
                    </section>
                </div>
            </form>
        </div>
    )
}

export default SelfAssessment;