import React, {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../../actions/popup';
import { useHistory } from 'react-router-dom';
import { ROUTE } from '../../../../common/constants/routes';
import Button from '../../../../components/FormComponents/Button';
import './SelfAssessmentModal.scss';

export default function SelfAssessmentModal({requestId, action, socialName, handleSubmit, userRol}) {
    const { t } = useTranslation();
	const dispatch = useDispatch();
    const history = useHistory()

    const postQuestionnaire = (e, action) => {
        e.preventDefault()
        dispatch(closeModal())
        handleSubmit(action)
    }
   
    const renderMessage = (action) => {
        let message = '';
        let submessage = '';
        switch (action) {
            case 'SaveQuestionnaire':
                message = 'Podrás seguir cumplimentándolo en cualquer momento.'
                break;
            case 'CancelQuestionnaire':
                message = '¿Seguro que quieres borrar tu cuestionario? Perderás todos los datos que hayas introducido.'
                break;
            case 'SendQuestionnaire':
                message = 'Vas a enviar tu cuestionario para su validación.';
                submessage = `Recuerda que tienes que subir a la plataforma el informe de <strong>${socialName}</strong> en un plazo no superior a <strong>15 días</strong> desde la realización de la visita de evaluación.<br/><br/>Un saludo de todo el equipo de Madrid Excelente.`;
                break;
            case 'EditQuestionnaire':
            message = ' '    
            submessage = 'Recuerda que si modificas la cumplimentación del cuestionario de autoevaluación, debes actualizar el informe de evaluación y la hoja de puntuación y gráficos.<br/><br/>Un saludo de todo el equipo de Madrid Excelente.'
                break;
            default:
                break;
        }

        return (
            <>            
                <p>{message}</p>
                { userRol === 'Auditor' && message != '' && <p className="submessage" dangerouslySetInnerHTML={{ __html: submessage}}></p>}
            </>

        )
    }

    return(
        <div className='selfAssessment-edit-modal'>
            <span className='message'>{renderMessage(action)}</span>
            <form className='form-user-edit'>
                <div className='btn-submit-container row mt-4'>
                    <div className='btn-submit-wrapper col-lg-12 d-flex justify-content-center'>
                        {
                            action === 'SaveQuestionnaire' ? (
                                <>
                                    <Button
                                        dataTestID="btnCancel"
                                        btnText={t('Cancel')}
                                        btnType="button"
                                        btnClass="btn-base_outline mr-3"
                                        onHandleBtnClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    />
                                    <Button
                                        btnText={t('SaveQuestionnaire')}
                                        btnType="button"
                                        isDisabled={false}
                                        btnClass="btn-base_normal "
                                        dataTestID="btnAuthorize"
                                        id='btnAuthorize'
                                        onHandleBtnClick={(e) => postQuestionnaire(e, 'save')}
                                    />
                                </>
                            ) : null
                        }
                        {
                            action === 'CancelQuestionnaire' ? (
                                <>
                                    <Button
                                        dataTestID="btnCancel"
                                        btnText={t('CancelQuestionnaire')}
                                        btnType="button"
                                        btnClass="btn-base_outline mr-3"
                                        onHandleBtnClick={() => {
                                            dispatch(closeModal());
                                            history.push({
                                                pathname: ROUTE.REQUESTDETAIL.replace(':id', requestId),
                                                state: {
                                                    requestId: requestId
                                                }
                                            })
                                        }}
                                    />
                                    <Button
                                        btnText={t('CompleteQuestionnaire')}
                                        btnType="button"
                                        isDisabled={false}
                                        btnClass="btn-base_normal "
                                        dataTestID="btnAuthorize"
                                        id='btnAuthorize'
                                        onHandleBtnClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    />
                                </>
                            ) : null
                        }
                        {
                            action === 'SendQuestionnaire' ? (
                                <>
                                    <Button
                                        dataTestID="btnCancel"
                                        btnText={t('ModifyQuestionnaire')}
                                        btnType="button"
                                        btnClass="btn-base_outline mr-3"
                                        onHandleBtnClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    />
                                    <Button
                                        btnText={t('SendQuestionnaire')}
                                        btnType="button"
                                        isDisabled={false}
                                        btnClass="btn-base_normal "
                                        dataTestID="btnAuthorize"
                                        id='btnAuthorize'
                                        onHandleBtnClick={(e) => postQuestionnaire(e, 'send')}
                                    />
                                </>
                            ) : null
                        }
                        {
                            action === 'EditQuestionnaire' ? (
                                <>
                                    <Button
                                        dataTestID="btnCancel"
                                        btnText={t('Cancel')}
                                        btnType="button"
                                        btnClass="btn-base_outline mr-3"
                                        onHandleBtnClick={() => {
                                            dispatch(closeModal());
                                        }}
                                    />
                                    <Button
                                        btnText={t('ModifyQuestionnaire')}
                                        btnType="button"
                                        isDisabled={false}
                                        btnClass="btn-base_normal "
                                        dataTestID="btnAuthorize"
                                        id='btnAuthorize'
                                        onHandleBtnClick={(e) => postQuestionnaire(e, 'edit')}
                                    />
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}








